import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ContactUs } from "../models/contactUs";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class ContactUsService {

	private getContactUrl = environment.baseURL + "contact/getContact";
	private getContactByIdUrl = environment.baseURL + "contact/getContactById";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getContact(params: any): Observable<ContactUs> {
		return this.http
			.post<ContactUs>(this.getContactUrl, params, this.httpOptions)
			.pipe(
				map((contactList) => {
					return contactList;
				})
			);
	}

	getContactById(contactId: any): Observable<ContactUs> {
		let contactIds = { contactId: contactId };
		return this.http
			.post<ContactUs>(this.getContactByIdUrl, contactIds, this.httpOptions)
			.pipe(
				map((contactList) => {
					return contactList;
				})
			);
	}
}