import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terms-condition',
	templateUrl: './terms-condition.component.html',
	styleUrls: [

	]
})
export class TermsConditionComponent implements OnInit {
	constructor() { }
	ngOnInit(): void { }
}
