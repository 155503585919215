import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { HospitalTypesService } from "../services/hospitalTypes.service";
import { HospitalTypesModel } from "../models/hospitalTypes";

@Component({
	selector: 'app-hospital-types',
	templateUrl: './hospital-types.component.html',
	styleUrls: ['./hospital-types.component.css']
})
export class HospitalTypesComponent implements OnInit {


	dtOptions: DataTables.Settings = {};
	closeResult = "";

	hospitalList: any;
	deleteHospitalId: any;

	updateHospitalForm = new FormGroup({
		description: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		hospitalTypeId: new FormControl("", [Validators.required]),

	});

	addHospitalForm = new FormGroup({
		description: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),

	});

	errorMessage = "";
	successMessage = "";
	errorMessageDelete = "";
	successMessageDelete = "";

	get f() {
		return this.addHospitalForm.controls;
	}

	constructor(
		private hospitalService: HospitalTypesService,
		private hospitalModalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getHospital();
	}

	getHospital(): void {
		this.hospitalList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.hospitalService.getHospitalApi(dataTablesParameters).subscribe(
					(data) => {
						that.hospitalList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openHospitalEdit(content: any, Id: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.hospitalService.getHospitalById(Id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateHospitalForm.controls["description"].setValue(data.data.description);
					this.updateHospitalForm.controls["name"].setValue(data.data.name);
					this.updateHospitalForm.controls["status"].setValue(data.data.status);
					this.updateHospitalForm.controls["hospitalTypeId"].setValue(data.data.id);

				} else {
				}
			},
			(error) => {
			}
		);
		this.hospitalModalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	updateHospitalTypes($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let description = this.updateHospitalForm.value.description.trim();
		let name = this.updateHospitalForm.value.name.trim();
		let status = this.updateHospitalForm.value.status.trim();
		let hospitalTypeId = this.updateHospitalForm.value.hospitalTypeId;

		if (
			!description ||
			!name ||
			!status ||
			!hospitalTypeId
		) {

			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.hospitalService
			.updateHospital({
				description,
				name,
				status,
				hospitalTypeId
			} as HospitalTypesModel)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openHospitalAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.hospitalModalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addHospital($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let description = this.addHospitalForm.value.description.trim();
		let name = this.addHospitalForm.value.name.trim();
		let status = this.addHospitalForm.value.status.trim();
		if (
			!description ||
			!name ||
			!status
		) {

			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;

			return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.hospitalService
			.addHospitalApi({
				description,
				name,
				status
			} as HospitalTypesModel)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							document.getElementById("addAmenityManageModalClose")?.click();
							this.addHospitalForm.reset();
						}, 2000);
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	deleteHospitalConformation(content: any, Id: any): void {
		this.deleteHospitalId = Id;
		this.hospitalModalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}

	deleteHospital(): void {

		let hospitalTypeId = this.deleteHospitalId
		if (!hospitalTypeId) {
			this.errorMessageDelete = "Something wrong happen.";
			return;
		}
		this.hospitalService.deleteHospitalTypes({ hospitalTypeId } as HospitalTypesModel).subscribe(
			(data) => {
				this.errorMessageDelete = "";
				if (data.status == 200) {
					this.successMessageDelete = data.message;
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessageDelete = data.message;
				}
			},
			(error) => {
				this.errorMessageDelete = error;
			}
		);
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}


}
