import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DepartmentManageComponent } from './department-manage.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'department_manage',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: DepartmentManageComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DepartmentManageRoutingModule { }