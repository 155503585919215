import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BannerListRoutingModule } from "./banner-list-routing.module";
import { BannerListComponent } from "./banner-list.component";

@NgModule({
  imports: [
    CommonModule,
    BannerListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BannerListComponent],
  providers: [AuthGuard],
})
export class BannerListModule { }
