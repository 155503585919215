import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommunityService } from "../services/community.service";


@Component({
	selector: 'app-community-details-logs',
	templateUrl: './community-details-logs.component.html',
	styleUrls: ['./community-details-logs.component.css']
})

export class CommunityDetailsLogsComponent implements OnInit {

	userId: any;
	userById: any;


	constructor(
		private CommunityService: CommunityService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getCommunityLogsById(this.userId);
	}

	getCommunityLogsById(userId: any): void {
		this.CommunityService.getCommunityLogsApi(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;

				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}



}
