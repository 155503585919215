import { Component, OnInit } from '@angular/core';

import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [
		'./profile.component.css'
	]
})
export class ProfileComponent implements OnInit {

	profileContentById: any;

	constructor(
		private CommonService: CommonService,
	) { }

	ngOnInit() {
		this.ProfileView();
	}

	ProfileView(): void {
		this.CommonService.getProfile()
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.profileContentById = data.data;
					} else {
						this.profileContentById = [];
					}
				},
				(error) => {
				}
			);
	}
}


