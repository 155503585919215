import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BadgeListRoutingModule } from "./badge-list-routing.module";
import { BadgeListComponent } from "./badge-list.component";

@NgModule({
  imports: [
    CommonModule,
    BadgeListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BadgeListComponent],
  providers: [AuthGuard],
})
export class BadgeListModule { }
