import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Amenity } from "../models/amenity";
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class AmenityService {

	private getAmenityUrl = environment.baseURL + "amenity/getAmenity";
	private addAmenityUrl = environment.baseURL + "amenity/createAmenity";
	private getAmenityByIdUrl = environment.baseURL + "amenity/getAmenityById";
	private updateAmenityUrl = environment.baseURL + "amenity/updateAmenity";
	private deleteAmenityUrl = environment.baseURL + "amenity/deleteAmenity";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getAmenity(params: any): Observable<Amenity> {
		return this.http
			.post<Amenity>(this.getAmenityUrl, params, this.httpOptions)
			.pipe(
				map((amenityList) => {
					return amenityList;
				})
			);
	}

	addAmenity(params: Amenity): Observable<Amenity> {
		return this.http
			.post<Amenity>(this.addAmenityUrl, params, this.httpOptions)
			.pipe(
				map((amenityList) => {
					return amenityList;
				})
			);
	}

	getAmenityById(amenityId: any): Observable<Amenity> {
		let amenityIds = { amenityId: amenityId };
		return this.http
			.post<Amenity>(this.getAmenityByIdUrl, amenityIds, this.httpOptions)
			.pipe(
				map((amenityList) => {
					return amenityList;
				})
			);
	}

	updateAmenity(amenityId: Amenity): Observable<Amenity> {
		return this.http
			.post<Amenity>(this.updateAmenityUrl, amenityId, this.httpOptions)
			.pipe(
				map((amenityList) => {
					return amenityList;
				})
			);
	}
	
	deleteAmenity(params: Amenity): Observable<Amenity> {
		return this.http
			.post<Amenity>(this.deleteAmenityUrl, params, this.httpOptions)
			.pipe(
				map((amenityList) => {
					return amenityList;
				})
			);
	}
}