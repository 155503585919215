import { Component, OnInit, ViewChild  } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { AmenityService } from "../services/amenity.service";
import { RoleService } from "../services/role.service";
import { Amenity } from "../models/amenity";

@Component({
	selector: 'app-amenity-manage',
	templateUrl: './amenity-manage.component.html',
	styleUrls: ['./amenity-manage.component.css']
})
export class AmenityManageComponent implements OnInit {
	
	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";
	currentImage: any;
	amenityList: any;
	roleList: any;
	deleteAmenityId: any;
	images: string[] = [];

	errorMessage = "";
	successMessage = "";

	addAmenityForm = new FormGroup({
		role_id: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateAmenityForm = new FormGroup({
		role_id: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		amenityId: new FormControl("", [Validators.required])
	});

	constructor(
		private amenityService: AmenityService,
		private roleService: RoleService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getAmenity();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getAmenity(): void {
		this.amenityList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.amenityService.getAmenity(dataTablesParameters).subscribe(
					(data) => {
						that.amenityList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openAmenityAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.roleService.getRole().subscribe(
			(data) => {
				if (data.status == 200) {
					this.roleList = data.data;
				} else {
					this.roleList = [];
				}
			}
		);
		this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.addAmenityForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	addAmenity($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let role_id = this.addAmenityForm.value.role_id.trim();
		let name = this.addAmenityForm.value.name.trim();
		let image = this.addAmenityForm.value.image;
		let status = this.addAmenityForm.value.status.trim();
		if (
			!role_id ||
			!name ||
			!image ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.amenityService
			.addAmenity({
				role_id,
				name,
				image,
				status
			} as Amenity)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.addAmenityForm.reset(
							{
								"name": "",
								"role_id": "",
								"image": "",
								"status": ""
							}
						);
						document.getElementById("addAmenityModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openAmenityEdit(content: any, amenityId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.roleService.getRole().subscribe(
			(data) => {
				if (data.status == 200) {
					this.roleList = data.data;
				} else {
					this.roleList = [];
				}
			}
		);
		this.amenityService.getAmenityById(amenityId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateAmenityForm.controls["role_id"].setValue(data.data.role_id);
					this.updateAmenityForm.controls["image"].setValue(data.data.image);
					this.currentImage = data.data.image;
					this.updateAmenityForm.controls["name"].setValue(data.data.name);
					this.updateAmenityForm.controls["status"].setValue(data.data.status);
					this.updateAmenityForm.controls["amenityId"].setValue(data.data.id);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.updateAmenityForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	updateAmenity($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let role_id = this.updateAmenityForm.value.role_id;
		let image = this.updateAmenityForm.value.image;
		let name = this.updateAmenityForm.value.name.trim();
		let status = this.updateAmenityForm.value.status;
		let amenityId = this.updateAmenityForm.value.amenityId;

		if (
			!role_id ||
			!image ||
			!name ||
			!amenityId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.amenityService
			.updateAmenity({
				role_id,
				image,
				name,
				status,
				amenityId
			} as Amenity)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateAmenityForm.reset(
							{
								"name": "",
								"role_id": "",
								"image": "",
								"status": "",
								"amenityId": ""
							}
						);
						document.getElementById("updateAmenityModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openAmenityDelete(content: any, amenityId: any): void {
		this.deleteAmenityId = amenityId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}

	deleteAmenity(): void {
		let amenityId = this.deleteAmenityId;
		if (!amenityId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.amenityService.deleteAmenity({ amenityId } as Amenity).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteAmenityModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getAmenity();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}