<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Blood Request Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card">
					<div class="card-body py-4">
						<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
							<thead>
								<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
									<th>SR. No.</th>
									<th>Patient Name</th>
									<th>Blood Type</th>
									<th>Required Date</th>
									<th>Status</th>
									<th>Created</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody class="text-gray-600 fw-semibold" *ngIf="bloodRequestList?.length != 0">
								<tr *ngFor="let bloodRequest of bloodRequestList;let i = index">
									<td>{{i+1}}</td>
									<td>{{bloodRequest.patient_name}}<span *ngIf="bloodRequest.is_critical" class="badge badge-light-danger">Critical</span></td>
									<td>{{bloodRequest.blood_type}}</td>
									<td>{{bloodRequest.required_date}}</td>
									<td *ngIf="bloodRequest.status"><span class="badge badge-light-success">Active</span></td>
									<td *ngIf="!bloodRequest.status"><span class="badge badge-light-danger">Inactive</span></td>
									<td>{{bloodRequest.date_filter}}</td>
									<td>
										<a href="blood_request_details/{{bloodRequest.id}}"
											class="btn btn-sm btn-icon badge badge-light-primary">
											<i class="fa fa-eye text-primary"></i>
										</a>
									</td>
								</tr>
							</tbody>
							<tbody class="text-gray-600 fw-semibold" *ngIf="bloodRequestList?.length == 0">
								<tr>
									<td>No Record Found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>