import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JwPaginationModule } from "jw-angular-pagination";

import { AuthGuard } from "./auth.guard";

import { LoginModule } from "./login/login.module";

import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";

import { TermsConditionModule } from "./terms-condition/terms-condition.module";

import { DeleteAccountModule } from "./delete-account/delete-account.module";

import { DeleteAccountThankyouModule } from "./delete-account-thankyou/delete-account-thankyou.module";

import { VerifyOtpModule } from "./verify-otp/verify-otp.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { RoleListModule } from "./role-list/role-list.module";

import { UserListModule } from "./user-list/user-list.module";

import { UserViewModule } from "./user-view/user-view.module";

import { BannerListModule } from "./banner-list/banner-list.module";

import { DiseaseListModule } from "./disease-list/disease-list.module";

import { BloodRequestListModule } from "./blood-request-list/blood-request-list.module";

import { BloodRequestDetailsModule } from "./blood-request-details/blood-request-details.module";

import { BloodTypeListModule } from "./blood-type-list/blood-type-list.module";

import { AppointmentListModule } from "./appointment-list/appointment-list.module";

import { CommunityListModule } from "./community-list/community-list.module";

import { CommunityDetailsModule } from "./community-details/community-details.module";

import { BadgeListModule } from "./badge-list/badge-list.module";

import { VersionControlListModule } from "./version-control-list/version-control-list.module";

import { ProfileModule } from "./profile/profile.module";

import { UpdateProfileModule } from "./update-profile/update-profile.module";

import { ChangePasswordModule } from "./change-password/change-password.module";

import { LogoutModule } from "./logout/logout.module";

import { EmailTemplateManageModule } from './email-template-manage/email-template-manage.module';

import { BusinessMasterModule } from './business-master/business-master.module';

import { BusinessMasterDetailsModule } from './business-master-details/business-master-details.module';

import { MasterSettingsModule } from './master-settings/master-settings.module';

import { CommunityDetailsPostModule } from './community-details-post/community-details-post.module';

import { CommunityDetailsUserModule } from './community-details-user/community-details-user.module';

import { AmenityManageModule } from './amenity-manage/amenity-manage.module';

import { DepartmentManageModule } from './department-manage/department-manage.module';

import { HospitalTypesModule } from './hospital-types/hospital-types.module';

import { ArticleCategoryModule } from './article-category/article-category.module';

import { ArticleManageModule } from './article-manage/article-manage.module';

import { ArticleAddModule } from './article-add/article-add.module';

import { ArticleEditModule } from './article-edit/article-edit.module';

import { DoctorManageModule } from './doctor-manage/doctor-manage.module';

import { CommunityDetailsLogsModule } from './community-details-logs/community-details-logs.module';

import { ContactUsModule } from './contact-us/contact-us.module';

import { NewslatterManageModule } from './newslatter-manage/newslatter-manage.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JwPaginationModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    DashboardModule,
    PrivacyPolicyModule,
    TermsConditionModule,
    LoginModule,
    UserListModule,
    UserViewModule,
    BannerListModule,
    DiseaseListModule,
    BloodTypeListModule,
    BloodRequestListModule,
    BloodRequestDetailsModule,
    RoleListModule,
    DeleteAccountModule,
    DeleteAccountThankyouModule,
    AppointmentListModule,
    CommunityListModule,
    CommunityDetailsModule,
    NewslatterManageModule,
    ContactUsModule,
    AmenityManageModule,
    DepartmentManageModule,
    HospitalTypesModule,
    ArticleCategoryModule,
    ArticleManageModule,
    ArticleAddModule,
    ArticleEditModule,
    DoctorManageModule,
    CommunityDetailsLogsModule,
    BadgeListModule,
    VersionControlListModule,
    EmailTemplateManageModule,
    BusinessMasterModule,
    BusinessMasterDetailsModule,
    MasterSettingsModule,
    CommunityDetailsPostModule,
    CommunityDetailsUserModule,
    VerifyOtpModule,
    ProfileModule,
    UpdateProfileModule,
    ChangePasswordModule,
    LogoutModule,

  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule { }
