import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { NewslatterManageService } from "../services/newslatterManage.service";

@Component({
	selector: 'app-newslatter-manage',
	templateUrl: './newslatter-manage.component.html',
	styleUrls: ['./newslatter-manage.component.css']
})

export class NewslatterManageComponent implements OnInit {


	dtOptions: DataTables.Settings = {};

	closeResult = "";

	newsList: any;
	newsById: any;



	constructor(
		private newsService: NewslatterManageService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getNewslatter();
	}

	getNewslatter(): void {
		this.newsList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.newsService.getNewsApi(dataTablesParameters).subscribe(
					(data) => {
						that.newsList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

}
