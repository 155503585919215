import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { DepartmentService } from "../services/department.service"
import { Department } from "../models/department";

@Component({
	selector: 'app-department-manage',
	templateUrl: './department-manage.component.html',
	styleUrls: ['./department-manage.component.css']
})
export class DepartmentManageComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	departmentList: any;
	deleteDepartmentId: any;
	currentImage: any;
	images: string[] = [];
	
	errorMessage = "";
	successMessage = "";
	
	addDepartmentForm = new FormGroup({
		type: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateDepartmentForm = new FormGroup({
		type: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		departmentId: new FormControl("", [Validators.required]),
	});

	constructor(
		private departmentService: DepartmentService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getDepartment();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getDepartment(): void {
		this.departmentList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.departmentService.getDepartment(dataTablesParameters).subscribe(
					(data) => {
						that.departmentList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openDepartmentAdd(content: any): void {
		this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.addDepartmentForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	addDepartment($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let type = this.addDepartmentForm.value.type.trim();
		let name = this.addDepartmentForm.value.name.trim();
		let image = this.addDepartmentForm.value.image;
		let status = this.addDepartmentForm.value.status.trim();
		if (
			!type ||
			!name ||
			!image ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.departmentService
			.addDepartment({
				type,
				name,
				image,
				status
			} as Department)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.addDepartmentForm.reset(
							{
								"name": "",
								"type": "",
								"image": "",
								"status": ""
							}
						);
						document.getElementById("addDepartmentModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDepartmentEdit(content: any, departmentId: any): void {
		this.departmentService.getDepartmentById(departmentId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDepartmentForm.controls["type"].setValue(data.data.type);
					this.updateDepartmentForm.controls["image"].setValue(data.data.image);
					this.currentImage = data.data.image;
					this.updateDepartmentForm.controls["name"].setValue(data.data.name);
					this.updateDepartmentForm.controls["status"].setValue(data.data.status);
					this.updateDepartmentForm.controls["departmentId"].setValue(data.data.id);
				} 
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.updateDepartmentForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	updateDepartment($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let type = this.updateDepartmentForm.value.type.trim();
		let image = this.updateDepartmentForm.value.image;
		let name = this.updateDepartmentForm.value.name.trim();
		let status = this.updateDepartmentForm.value.status;
		let departmentId = this.updateDepartmentForm.value.departmentId;
		if (
			!type ||
			!image ||
			!name ||
			!departmentId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.departmentService
			.updateDepartment({
				type,
				name,
				image,
				status,
				departmentId
			} as Department)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateDepartmentForm.reset(
							{
								"name": "",
								"type": "",
								"image": "",
								"status": "",
								"amenityId": ""
							}
						);
						document.getElementById("updateDepartmentModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDepartmentDelete(content: any, departmentId: any): void {
		this.deleteDepartmentId = departmentId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteDepartment(): void {
		let departmentId = this.deleteDepartmentId;
		if (!departmentId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.departmentService.deleteDepartment({ departmentId } as Department).subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteDepartmentModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getDepartment();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}