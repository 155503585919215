import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user';

import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: [

	]
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm = new FormGroup({
		password: new FormControl('', [Validators.required]),
		new_password: new FormControl('', [Validators.required]),
		c_new_password: new FormControl('', [Validators.required]),
	});

	userId: any;
	errorMessage = '';
	successMessage = '';

	constructor(
		private commonService: CommonService,
	) { }

	ngOnInit(): void {
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.userId = userArray.data.id
		}
	}

	changePassword(): void {
		this.errorMessage = '';
		this.successMessage = '';

		let userId = this.userId;
		let password = this.changePasswordForm.value.password.trim();
		let new_password = this.changePasswordForm.value.new_password.trim();
		let c_new_password = this.changePasswordForm.value.c_new_password.trim();

		if (!password || !new_password || !c_new_password) {
			this.errorMessage = 'All fields are required.';
			return;
		} else {
			if (new_password != c_new_password) {
				this.errorMessage = 'New password not matched';
				return;
			}
		}
		this.commonService.changePassword({ password, new_password, c_new_password, userId } as User)
			.subscribe(
				data => {
					if (data.status == 200) {
						this.errorMessage = "";
						this.successMessage = data.message;
						setTimeout(() => {
							window.location.href = 'dashboard';
						}, 2000);
					} else {
						this.errorMessage = data.message;
						return;
					}
				},
				error => {
					this.errorMessage = error;
					return;
				}
			);
	}
}