import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { RoleListRoutingModule } from "./role-list-routing.module";
import { RoleListComponent } from "./role-list.component";

@NgModule({
  imports: [
    CommonModule,
    RoleListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [RoleListComponent],
  providers: [AuthGuard],
})
export class RoleListModule { }
