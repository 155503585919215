import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BloodTypeListRoutingModule } from "./blood-type-list-routing.module";
import { BloodTypeListComponent } from "./blood-type-list.component";

@NgModule({
  imports: [
    CommonModule,
    BloodTypeListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BloodTypeListComponent],
  providers: [AuthGuard],
})
export class BloodTypeListModule { }
