import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { DiseaseService } from "../services/disease.service";
import { Disease } from "../models/disease";

@Component({
	selector: "app-disease-list",
	templateUrl: "./disease-list.component.html",
	styleUrls: ["./disease-list.component.css"],
})
export class DiseaseListComponent implements OnInit {
	
	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	diseaseList: any;
	deleteDiseaseId: any;

	errorMessage = "";
	successMessage = "";

	addDiseaseForm = new FormGroup({
		question: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateDiseaseForm = new FormGroup({
		question: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		diseaseId: new FormControl("", [Validators.required]),
	});

	constructor(
		private diseaseService: DiseaseService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getDisease();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getDisease(): void {
		this.diseaseList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.diseaseService.getDisease(dataTablesParameters).subscribe(
					(data) => {
						that.diseaseList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "url" }, { data: "created" }],
		};
	}

	openDiseaseAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addDisease($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let question = this.addDiseaseForm.value.question.trim();
		let status = this.addDiseaseForm.value.status.trim();
		if (
			!question ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.diseaseService.addDisease({ question, status } as Disease)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.addDiseaseForm.reset(
							{
								"question": "",
								"status": ""
							}
						);
						document.getElementById("addDiseaseModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDiseaseEdit(content: any, diseaseId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.diseaseService.getDiseaseById(diseaseId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDiseaseForm.controls["question"].setValue(data.data.question);
					this.updateDiseaseForm.controls["diseaseId"].setValue(data.data.id);
					this.updateDiseaseForm.controls["status"].setValue(data.data.status);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	updateDisease($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let question = this.updateDiseaseForm.value.question.trim();
		let status = this.updateDiseaseForm.value.status;
		let diseaseId = this.updateDiseaseForm.value.diseaseId;
		if (
			!question ||
			!diseaseId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.diseaseService
			.updateDisease({
				question,
				status,
				diseaseId
			} as Disease)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateDiseaseForm.reset(
							{
								"question": "",
								"status": "",
								"diseaseId": ""
							}
						);
						document.getElementById("updateDiseaseModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDiseaseDelete(content: any, diseaseId: any): void {
		this.deleteDiseaseId = diseaseId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteDisease(): void {
		let diseaseId = this.deleteDiseaseId
		if (!diseaseId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.diseaseService.deleteDisease({ diseaseId } as Disease).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteDiseaseModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getDisease();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}
