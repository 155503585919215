<body id="kt_body" class="auth-bg">
    <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-column flex-lg-row flex-column-fluid">
            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div class="">
                        <div style="text-align: center;">
                            <img src="assets/media/avatars/logo.png" alt="" style="width: 15%;" />
                        </div>
                        <h1>Privacy Policy</h1>
                        <p>Last updated: February 09, 2024</p>

                        <p><strong>1. </strong>This document is an electronic record in terms of the Information
                            Technology Act, 2000 and rules there under as applicable and the amended provisions
                            pertaining to electronic records in various statutes as amended by the Information
                            Technology Act, 2000. This electronic record is generated by a computer system and does not
                            require any physical or digital signature.</p>

                        <p><strong>2. </strong>This document is published in accordance with the provisions of Rule 3
                            (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require
                            publishing the rules and regulations, privacy policy and terms of use for access or usage of
                            the domain name ("Website") and the corresponding application ("Application")</p>

                        <p><strong>3. </strong>By accessing the Website or Application, whether through a mobile device,
                            mobile application or computer, the User agrees to be bound by the Terms of Use ('Terms')
                            and this Privacy Policy ('Policy') whether or not the User creates an account to avail of
                            the Service. If the User wishes to avail of the Service, the User does hereby unequivocally
                            accept and agree to the contents of the Terms and Policy</p>

                        <p><strong>4. </strong>For the purpose of these Terms, wherever the context so requires:</p>

                        <p><strong>4.1 </strong>The term 'the User' & 'User' & 'Member' & 'You' shall mean any legal
                            person or entity accessing or using the Service (defined below) provided on the Application,
                            who is competent to enter into binding contracts, as per the provisions of the Indian
                            Contract Act, 1872;</p>

                        <p><strong>4.2 </strong>The terms 'Party' & 'Parties' shall respectively be used to refer to the
                            User and the Company individually and collectively, as the context so requires; and</p>

                        <p><strong>4.3 </strong>The terms 'We' and 'Us' shall be used to refer to the Company.</p>

                        <p><strong>5. </strong>The headings of each section in these Terms are only for the purpose of
                            organizing the various provisions under these Terms in an orderly manner, and shall not be
                            used by either Party to interpret the provisions contained herein in any manner. Further, it
                            is specifically agreed to by the Parties that the headings shall have no legal or
                            contractual value.</p>

                        <p><strong>6. </strong>The Application has been designed by the Company to assist the User in
                            connecting with other Users of the application, in providing technical accessibility between
                            the Users, and creating a common platform wherein transactions between the Users are carried
                            out in a seamless and efficient manner, using various third party products/services to be
                            decided at the discretion of the Company, to be added to the Application from time to time,
                            including but not limited to third party payment gateways ("Service").</p>

                        <p><strong>7. </strong>The use of the Website and Application by the User is solely governed by
                            these Terms read with the Privacy Policy and any modifications or amendments made thereto by
                            the Company from time to time, at its sole discretion. Accessing the Application and/or
                            using any of the information or Services provided therein shall be deemed to signify the
                            User's unequivocal acceptance of these Terms and the aforementioned Policy, and the User
                            expressly agrees to be bound by the same, regardless of however the User or anyone on the
                            User's behalf has accessed, installed, downloaded or used the Application. The User
                            expressly agrees and acknowledges that the Terms and Policy are co-terminus, and that expiry
                            / termination of either one will lead to the termination of the other. The User may not
                            access the Application or avail of any of the Services provided by the Company if the User
                            does not accept and agree to be bound by the Terms and Policy. In the event that the User or
                            anyone acting on the User's behalf does not wish to be bound by the Terms and the Policy,
                            the User (or the legal person/entity acting on the User's behalf) unequivocally agrees to
                            refrain from accessing, using or retaining the Application on any device in any manner
                            whatsoever. The User agrees that anything done or caused to be done by the User or anyone
                            acting on the User's behalf, whether expressly or impliedly in contravention with the Terms
                            or Policy, will render the User liable for legal and punitive action.</p>

                        <p><strong>8. </strong>COLLECTION OF PERSONAL AND OTHER INFORMATION</p>

                        <p><strong>8.1 </strong>The User is aware and acknowledges that the Company collects and stores
                            the User's personal information, which is provided by the User from time to time through the
                            Application, including but not limited to the User's user name, passwords, email address,
                            name, address, age, date of birth, sex, nationality, geographical location, web browsing
                            history, etc., as well as any images, chat messages or other information uploaded /
                            published by the User through the Application. The User is aware that this information will
                            be used by the Company/Application to provide services and features targeted at the User,
                            that are most likely to meet the User's needs, and also to customize and improve the
                            Application to make its users' experiences safer and easier.</p>

                        <p><strong>8.2 </strong>The User is aware that the Company/Application may automatically track
                            certain information about the User, based upon but not limited to, the User's IP address and
                            the User's use of the Application, and the User expressly consents to the same. The User is
                            expressly made aware that such information may include the browsing history of the User,
                            whether or not a part of/through the Application.</p>

                        <p><strong>8.3 </strong>If the User chooses to avail of the Service through the Application, the
                            User consents to allowing the Company/Application to collect information about the User's
                            behavior and trends.</p>

                        <p><strong>8.4 </strong>The User is aware that any and all information pertaining to the User
                            collected by the Company, whether or not directly provided by the User to the
                            Company/Application, including but not limited to personal correspondence such as emails,
                            feedback from other users or third parties regarding the User's activities or postings on
                            the Application, etc., may be collected and complied by the Company/Application into a
                            file/folder specifically created for / allotted to the User, and the User hereby expressly
                            consents to the same.</p>

                        <p><strong>8.5 </strong>The User is aware that while he/she may be able to browse/access some
                            sections/features of the Application without being a registered user, certain activities,
                            including but not limited to availing of the Service may require the User to provide valid
                            personal information to the Company/Application for the purpose of registration.</p>

                        <p><strong>9. </strong>COOKIES</p>

                        <p><strong>9.1 </strong>The User is aware that a 'Cookie' is a small piece of information stored
                            by a web server on a web browser so it can later be traced back from that particular
                            browser, and that cookies are useful for enabling the browser to remember information
                            specific to a given user, including but not limited to a User's login identification,
                            password, etc. The User is aware that the Application places both permanent and temporary
                            cookies in the User's computer's hard drive and web browser, and does hereby expressly
                            consent to the same.</p>

                        <p><strong>9.2 </strong>The User is further aware that the Application uses data collection
                            devices such as cookies to help analyze web page flow, measure promotional effectiveness,
                            and promote trust and safety, and that certain features of the Application are only
                            available through the use of such cookies. While the User is free to decline the
                            Application's cookies if the User's device permits, the User may consequently be unable to
                            use certain features on the Application.</p>

                        <p><strong>9.3 </strong>Additionally, the User is aware that he/she might encounter 'cookies' or
                            other similar devices on the Application that are placed by third parties or affiliates of
                            the Company. The User expressly agrees and acknowledges that the Company/Application does
                            not control the use of such cookies/other devices by third parties, that the
                            Company/Application is in no way responsible for the same, and that the User assumes any and
                            all risks in this regard.</p>

                        <p><strong>10. </strong>DIVULGING/SHARING OF PERSONAL INFORMATION</p>

                        <p><strong>10.1 </strong>The User is aware that the Application/Company may share the User's
                            personal information with other entities and affiliates to help detect and prevent identity
                            theft, fraud and other potentially illegal acts; correlate related or multiple accounts to
                            prevent abuse of the Application's services; and to facilitate joint or co-branded services,
                            where such services are provided by more than one entity.</p>

                        <p><strong>10.2 </strong>The User is aware that the Application/Company may disclose personal
                            information if required to do so by law or if the Application/Company in good faith believes
                            that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other
                            legal processes. The Application/Company may also disclose the User's personal information
                            to law enforcement offices, third party rights owners, or other third parties if it believes
                            that such disclosure is reasonably necessary to enforce the Terms or Policy; respond to
                            claims that an advertisement, posting or other content violates the rights of a third party;
                            or protect the rights, property or personal safety of its users, or the general public.</p>

                        <p><strong>10.3 </strong>The User is further aware that the Application/Company and its
                            affiliates may share / sell some or all of the User's personal information with other
                            business entities should the Company/Application (or its assets) plan to merge with, or be
                            acquired by such business entity, or in the event of re-organization, amalgamation, or
                            restructuring of the Company's business. Such business entity or new entity will continue to
                            be bound be the Terms and Policy, as may be amended from time to time.</p>

                        <p><strong>10.4 </strong>Notwithstanding anything contained herein or in the Terms of Service
                            accompanying the present Policy, the User acknowledges that the Company shall protect the
                            confidentiality of all data provided by the User to the Application, and the User
                            accordingly agrees that the Company shall not be held liable for any accidental
                            dissemination of data that has occurred in spite of the Company's best efforts and
                            procedures to maintain confidentiality.</p>

                        <p><strong>11. </strong>USER'S CONSENT</p>

                        <p><strong>11.1 </strong>By using the Application and/ or by providing information to the
                            Company through the Application, the User consents to the collection and use of the
                            information disclosed by the User on the Application in accordance with this Policy,
                            including but not limited to the User's consent to the Company/Application sharing/divulging
                            the User's information, as per the terms contained herein. Please refer to Donor & Receiver
                            Terms in addition to the Privacy policy for detailed understanding of the terms.</p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul>
                            <li>By email: contact&#64;donorzilla.com</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
