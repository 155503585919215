import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Department } from "../models/department";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class DepartmentService {

	private getDepartmentUrl = environment.baseURL + "department/getDepartment";
	private addDepartmentUrl = environment.baseURL + "department/createDepartment";
	private getDepartmentByIdUrl = environment.baseURL + "department/getDepartmentById";
	private updateDepartmentUrl = environment.baseURL + "department/updateDepartment";
	private deleteDepartmentUrl = environment.baseURL + "department/deleteDepartment";
	private getAllDepartmentUrl = environment.baseURL + "department/getAllDepartment";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getDepartment(params: any): Observable<Department> {
		return this.http
			.post<Department>(this.getDepartmentUrl, params, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}

	getDepartmentById(departmentId: any): Observable<Department> {
		let departmentIds = { departmentId: departmentId };
		return this.http
			.post<Department>(this.getDepartmentByIdUrl, departmentIds, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}

	updateDepartment(departmentId: Department): Observable<Department> {
		return this.http
			.post<Department>(this.updateDepartmentUrl, departmentId, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}

	addDepartment(role_id: Department): Observable<Department> {
		return this.http
			.post<Department>(this.addDepartmentUrl, role_id, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}

	deleteDepartment(departmentId: Department): Observable<Department> {
		return this.http
			.post<Department>(this.deleteDepartmentUrl, departmentId, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}

	getAllDepartment(): Observable<Department> {
		return this.http
			.get<Department>(this.getAllDepartmentUrl, this.httpOptions)
			.pipe(
				map((departmentList) => {
					return departmentList;
				})
			);
	}
}