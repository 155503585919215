import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Doctor } from "../models/doctor";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class DoctorService {

	private getDoctorURL = environment.baseURL + "doctor/getDoctorAdmin";
	private getDoctorByIdURL = environment.baseURL + "doctor/getDoctorById";
	private updateDoctorURL = environment.baseURL + "doctor/updateDoctor";
	private addDoctorURL = environment.baseURL + "doctor/createDoctor";
	private deleteDoctorURL = environment.baseURL + "doctor/deleteDoctor";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getDoctor(params: any): Observable<Doctor> {
		return this.http
			.post<Doctor>(this.getDoctorURL, params, this.httpOptions)
			.pipe(
				map((doctorList) => {
					return doctorList;
				})
			);
	}

	getDoctorById(doctorId: any): Observable<Doctor> {
		let doctorIds = { doctorId: doctorId };
		return this.http
			.post<Doctor>(this.getDoctorByIdURL, doctorIds, this.httpOptions)
			.pipe(
				map((doctorList) => {
					return doctorList;
				})
			);
	}

	updateDoctor(params: Doctor): Observable<Doctor> {
		return this.http
			.post<Doctor>(this.updateDoctorURL, params, this.httpOptions)
			.pipe(
				map((doctorList) => {
					return doctorList;
				})
			);
	}

	addDoctor(params: Doctor): Observable<Doctor> {
		return this.http
			.post<Doctor>(this.addDoctorURL, params, this.httpOptions)
			.pipe(
				map((doctorList) => {
					return doctorList;
				})
			);
	}

	deleteDoctor(doctorId: Doctor): Observable<Doctor> {
		return this.http
			.post<Doctor>(this.deleteDoctorURL, doctorId, this.httpOptions)
			.pipe(
				map((doctorList) => {
					return doctorList;
				})
			);
	}
}