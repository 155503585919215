import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { BannerService } from "../services/banner.service";
import { Banner } from "../models/banner";

@Component({
	selector: "app-banner-list",
	templateUrl: "./banner-list.component.html",
	styleUrls: ["./banner-list.component.css"],
})
export class BannerListComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	addBannerForm = new FormGroup({
		url: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateBannerForm = new FormGroup({
		url: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		bannerId: new FormControl("", [Validators.required]),
	});

	images: string[] = [];

	currentImage: any;
	bannerList: any;
	deleteBannerId: any;

	errorMessage = "";
	successMessage = "";

	constructor(
		private bannerService: BannerService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getBanner();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getBanner(): void {
		this.bannerList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bannerService.getBanner(dataTablesParameters).subscribe(
					(data) => {
						that.bannerList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "url" }, { data: "created" }],
		};
	}

	openBannerAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
		.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.addBannerForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	addBanner($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let url = this.addBannerForm.value.url.trim();
		let image = this.addBannerForm.value.image;
		let status = this.addBannerForm.value.status;
		if (!url || !status || !image) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.bannerService
			.addBanner({
				url,
				status,
				image
			} as Banner)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.addBannerForm.reset(
							{
								"url": "",
								"image": "",
								"status": ""
							}
						);
						document.getElementById("addBannerModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openBannerEdit(content: any, Id: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bannerService.getBannerById(Id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateBannerForm.controls["url"].setValue(data.data.url);
					this.updateBannerForm.controls["image"].setValue(data.data.image);
					this.currentImage = data.data.image;
					this.updateBannerForm.controls["status"].setValue(data.data.status);
					this.updateBannerForm.controls["bannerId"].setValue(data.data.id);
				} else {
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.updateBannerForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	updateBanner($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let url = this.updateBannerForm.value.url;
		let image = this.updateBannerForm.value.image;
		let status = this.updateBannerForm.value.status;
		let bannerId = this.updateBannerForm.value.bannerId;

		if (
			!url ||
			!image ||
			!bannerId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.bannerService
			.updateBanner({
				url,
				image,
				status,
				bannerId
			} as Banner)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateBannerForm.reset(
							{
								"name": "",
								"role_id": "",
								"image": "",
								"status": "",
								"amenityId": ""
							}
						);
						document.getElementById("updateBannerModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openBannerDelete(content: any, bannerId: any): void {
		this.deleteBannerId = bannerId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}

	deleteBanner(): void {
		let bannerId = this.deleteBannerId;
		if (!bannerId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.bannerService.deleteBanner({ bannerId } as Banner).subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteBannerModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getBanner();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}
