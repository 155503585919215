import { Component, OnInit } from '@angular/core';

import { DashboardService } from "../services/dashboard.service";

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: [
		'./dashboard.component.css'
	]
})
export class DashboardComponent implements OnInit {

	userCounter: any;
	bloodCounter: any;
	appointmentCounter: any;

	userList: any;
	bloodList: any;

	constructor(
		private dashboardService: DashboardService,
	) { }

	ngOnInit() {
		this.getUserCounterDashboard();
		this.getBloodCounterDashboard();
		this.getAppointmentCounterDashboard();

		this.getLatestBloodListDashboard();
		this.getLatestUserListDashboard();
	}

	getUserCounterDashboard(): void {
		this.userCounter = [];

		this.dashboardService.getUserCounterDashboard().subscribe(
			(data) => {
				if (data.status == 200) {
					this.userCounter = data.data;
				} else {
					this.userCounter = [];
				}
			}
		);
	}

	getBloodCounterDashboard(): void {
		this.bloodCounter = [];

		this.dashboardService.getBloodCounterDashboard().subscribe(
			(data) => {
				if (data.status == 200) {
					this.bloodCounter = data.data;
				} else {
					this.bloodCounter = [];
				}
			}
		);
	}

	getAppointmentCounterDashboard(): void {
		this.appointmentCounter = [];

		this.dashboardService.getAppointmentCounterDashboard().subscribe(
			(data) => {
				if (data.status == 200) {
					this.appointmentCounter = data.data;
				} else {
					this.appointmentCounter = [];
				}
			}
		);
	}

	getLatestBloodListDashboard(): void {
		this.bloodList = [];

		this.dashboardService.getLatestBloodListDashboard().subscribe(
			(data) => {
				if (data.status == 200) {
					this.bloodList = data.data;
				} else {
					this.bloodList = [];
				}
			}
		);
	}

	getLatestUserListDashboard(): void {
		this.userList = [];

		this.dashboardService.getLatestUserListDashboard().subscribe(
			(data) => {
				if (data.status == 200) {
					this.userList = data.data;
				} else {
					this.userList = [];
				}
			}
		);
	}
}