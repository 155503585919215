import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommunityService } from "../services/community.service";

@Component({
	selector: 'app-community-details-post',
	templateUrl: './community-details-post.component.html',
	styleUrls: ['./community-details-post.component.css']
})

export class CommunityDetailsPostComponent implements OnInit {

	userId: any;
	userById: any;


	constructor(
		private CommunityService: CommunityService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getCommunityById(this.userId);
	}

	getCommunityById(userId: any): void {
		this.CommunityService.getCommunityPostApi(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

}

