import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ArticleService } from "../services/article.service";
import { Article } from "../models/article";

@Component({
	selector: 'app-article-manage',
	templateUrl: './article-manage.component.html',
	styleUrls: ['./article-manage.component.css']
})

export class ArticleManageComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";
	articleList: any;
	deleteArticleId: any;

	errorMessage = "";
	successMessage = "";

	constructor(
		private articleService: ArticleService, 
		private modelService: NgbModal,
	) { }

	ngOnInit(): void {
		this.getArticle();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getArticle(): void {
		this.articleList = [];
		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.articleService.getArticle(dataTablesParameters).subscribe(
					(data) => {
						this.articleList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openArticleDelete(content: any, articleId: any): void {
		this.deleteArticleId = articleId;
		this.modelService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteArticle(): void {
		let articleId = this.deleteArticleId;
		if (!articleId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.articleService.deleteArticle({ articleId } as Article).subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteArticleModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getArticle();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}