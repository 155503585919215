import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { DoctorService } from "../services/doctor.service";
import { DepartmentService } from "../services/department.service"
import { BusinessMasterService } from "../services/businessMaster.service"
import { Doctor } from "../models/doctor";

@Component({
	selector: 'app-doctor-manage',
	templateUrl: './doctor-manage.component.html',
	styleUrls: ['./doctor-manage.component.css']
})
export class DoctorManageComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";
	doctorList: any;
	BusinessList: any;
	departmentList: any;
	deleteDoctorId: any;
	images: string[] = [];

	errorMessage = "";
	successMessage = "";
	
	addDoctorForm = new FormGroup({
		department_id: new FormControl("", [Validators.required]),
		b_id: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
	});

	updateDoctorForm = new FormGroup({
		department_id: new FormControl("", [Validators.required]),
		b_id: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		name: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
		doctorId: new FormControl("", [Validators.required]),
	});

	constructor(
		private doctorService: DoctorService,
		private departmentService: DepartmentService,
		private businessMasterServuces: BusinessMasterService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getDoctor();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getDoctor(): void {
		this.doctorList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.doctorService.getDoctor(dataTablesParameters).subscribe(
					(data) => {
						that.doctorList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openDoctorAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.departmentService.getAllDepartment().subscribe(
			(data) => {
				if (data.status == 200) {
					this.departmentList = data.data;
				} else {
					this.departmentList = [];
				}
			}
		);
		this.businessMasterServuces.getBusinessId().subscribe(
			(data) => {
				if (data.status == 200) {
					this.BusinessList = data.data;
				} else {
					this.BusinessList = [];
				}
			}
		);
		this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);

				this.addDoctorForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	addDoctor($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let department_id = this.addDoctorForm.value.department_id.trim();
		let b_id = this.addDoctorForm.value.b_id.trim();
		let image = this.addDoctorForm.value.image;
		let name = this.addDoctorForm.value.name.trim();
		let bio = this.addDoctorForm.value.bio;
		if (
			!department_id ||
			!b_id ||
			!name ||
			!image ||
			!bio
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.doctorService
			.addDoctor({
				department_id,
				b_id,
				name,
				image,
				bio
			} as Doctor)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.addDoctorForm.reset(
							{
								"department_id": "",
								"b_id": "",
								"name": "",
								"image": "",
								"status": ""
							}
						);
						document.getElementById("addDoctorModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDoctorEdit(content: any, doctorId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.departmentService.getAllDepartment().subscribe(
			(data) => {
				if (data.status == 200) {
					this.departmentList = data.data;
				} else {
					this.departmentList = [];
				}
			}
		);
		this.businessMasterServuces.getBusinessId().subscribe(
			(data) => {
				if (data.status == 200) {
					this.BusinessList = data.data;
				} else {
					this.BusinessList = [];
				}
			}
		);
		this.doctorService.getDoctorById(doctorId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDoctorForm.controls["department_id"].setValue(data.data.department_id);
					this.updateDoctorForm.controls["b_id"].setValue(data.data.b_id);
					this.updateDoctorForm.controls["image"].setValue(data.data.image);
					this.updateDoctorForm.controls["name"].setValue(data.data.name);
					this.updateDoctorForm.controls["bio"].setValue(data.data.bio);
					this.updateDoctorForm.controls["doctorId"].setValue(data.data.id);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.updateDoctorForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	updateDoctor($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let department_id = this.updateDoctorForm.value.department_id;
		let b_id = this.updateDoctorForm.value.b_id;
		let image = this.updateDoctorForm.value.image;
		let name = this.updateDoctorForm.value.name.trim();
		let bio = this.updateDoctorForm.value.bio;
		let doctorId = this.updateDoctorForm.value.id;
		if (
			!department_id ||
			!b_id ||
			!image ||
			!name ||
			!bio ||
			!doctorId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.doctorService
			.updateDoctor({
				department_id,
				b_id,
				image,
				name,
				bio,
				doctorId
			} as Doctor)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateDoctorForm.reset(
							{
								"name": "",
								"department_id": "",
								"b_id": "",
								"image": "",
								"status": "",
								"doctorId": ""
							}
						);
						document.getElementById("updateDoctorModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openDoctorDelete(content: any, doctorId: any): void {
		this.deleteDoctorId = doctorId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteDoctor(): void {
		let doctorId = this.deleteDoctorId;
		if (!doctorId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.doctorService.deleteDoctor({ doctorId } as Doctor).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteDoctorModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getDoctor();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}