import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { CommunityModel } from "../models/community";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class CommunityService {

	private getCommunityUrl = environment.baseURL + "community/getCommunity";

	private getCommunityByIdUrl = environment.baseURL + "community/getCommunityById";

	private addCommunityUrl = environment.baseURL + "community/createCommunity";

	private updateCommunityUrl = environment.baseURL + "community/updateCommunity";

	private deleteCommunityUrl = environment.baseURL + "community/deleteCommunity";

	private getCommunityPostByIdUrl = environment.baseURL + "community/getCommunityPostById";

	private getCommunityUserByIdUrl = environment.baseURL + "community/getCommunityUsersById";

	private getCommunityLogsByIdUrl = environment.baseURL + "community/getCommunityLogsById";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getCommunity(params: any): Observable<CommunityModel> {
		return this.http
			.post<CommunityModel>(this.getCommunityUrl, params, this.httpOptions)
			.pipe(
				map((CommunityList) => {
					return CommunityList;
				})
			);
	}

	getCommunityById(communityId: any): Observable<CommunityModel> {
		let communityIds = { communityId: communityId };
		return this.http
			.post<CommunityModel>(this.getCommunityByIdUrl, communityIds, this.httpOptions)
			.pipe(
				map((CommunityList) => {
					return CommunityList;
				})
			);
	}

	addCommunity(community: CommunityModel): Observable<CommunityModel> {
		return this.http
			.post<CommunityModel>(this.addCommunityUrl, community, this.httpOptions)
			.pipe(
				map((community) => {
					return community;
				})
			);
	}

	updateCommunity(community: CommunityModel): Observable<CommunityModel> {
		return this.http
			.post<CommunityModel>(this.updateCommunityUrl, community, this.httpOptions)
			.pipe(
				map((community) => {
					return community;
				})
			);
	}

	deleteCommunity(community: CommunityModel): Observable<CommunityModel> {
		return this.http
			.post<CommunityModel>(this.deleteCommunityUrl, community, this.httpOptions)
			.pipe(
				map((community) => {
					return community;
				})
			);
	}

	getCommunityPostApi(community_id: any): Observable<CommunityModel> {
		let communityIds = { community_id: community_id };
		return this.http
			.post<CommunityModel>(this.getCommunityPostByIdUrl, communityIds, this.httpOptions)
			.pipe(
				map((CommunityList) => {
					return CommunityList;
				})
			);
	}
	getCommunityUserApi(community_id: any): Observable<CommunityModel> {
		let communityIds = { community_id: community_id };
		return this.http
			.post<CommunityModel>(this.getCommunityUserByIdUrl, communityIds, this.httpOptions)
			.pipe(
				map((CommunityList) => {
					return CommunityList;
				})
			);
	}

	getCommunityLogsApi(community_id: any): Observable<CommunityModel> {
		let communityIds = { community_id: community_id };
		return this.http
			.post<CommunityModel>(this.getCommunityLogsByIdUrl, communityIds, this.httpOptions)
			.pipe(
				map((LogsList) => {
					return LogsList;
				})
			);
	}
}
