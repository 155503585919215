<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Change Password</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
					<div class="card-body p-9">
						<form class="needs-validation" novalidate [formGroup]="changePasswordForm"
							(ngSubmit)="changePassword()">
							<div class="form-group row">
								<label for="validationpassword" class="col-form-label passwordadduser">* Current
									Password:</label>
								<div class="col-12 d-flex">
									<input formControlName="password" type="password" class="form-control pass"
										placeholder="Password"
										pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,}$" required>
								</div>
							</div>
							<div class="form-group row">
								<label for="validationpassword" class="col-form-label passwordadduser">*New
									Password:</label>
								<div class="col-12 d-flex">
									<input formControlName="new_password" type="password" class="form-control pass"
										placeholder="Password"
										pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,}$" required>
								</div>
							</div>
							<div class="form-group row">
								<label for="validationconfirmpassword" class="col-form-label passwordadduser">*Re-enter
									New Password:</label>
								<div class="col-12 d-flex">
									<input formControlName="c_new_password" type="password"
										class="form-control confirmpass" placeholder="Confirm Password" required>
								</div>
							</div>
							<br>
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<div class="text-center pt-15">
								<a href="dashboard" class="btn btn-light me-3">Cancel</a>&nbsp;
								<button class="btn btn-primary primaryBtn" type="submit">Submit</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>