import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BloodType } from "../models/bloodType";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class BloodTypeService {

	private getBloodTypeUrl = environment.baseURL + "bloodType/getBloodType";

	private getBloodTypeByIdUrl = environment.baseURL + "bloodType/getBloodTypeById";

	private addBloodTypeUrl = environment.baseURL + "bloodType/createBloodType";

	private updateBloodTypeUrl = environment.baseURL + "bloodType/updateBloodType";

	private deleteBloodTypeUrl = environment.baseURL + "bloodType/deleteBloodType";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getBloodType(params: any): Observable<BloodType> {
		return this.http
			.post<BloodType>(this.getBloodTypeUrl, params, this.httpOptions)
			.pipe(
				map((bloodTypeList) => {
					return bloodTypeList;
				})
			);
	}

	getBloodTypeById(bloodTypeId: any): Observable<BloodType> {
		let bloodTypeIds = { bloodTypeId: bloodTypeId };
		return this.http
			.post<BloodType>(this.getBloodTypeByIdUrl, bloodTypeIds, this.httpOptions)
			.pipe(
				map((bloodTypeList) => {
					return bloodTypeList;
				})
			);
	}

	addBloodType(bloodType: BloodType): Observable<BloodType> {
		return this.http
			.post<BloodType>(this.addBloodTypeUrl, bloodType, this.httpOptions)
			.pipe(
				map((bloodType) => {
					return bloodType;
				})
			);
	}

	updateBloodType(bloodType: BloodType): Observable<BloodType> {
		return this.http
			.post<BloodType>(this.updateBloodTypeUrl, bloodType, this.httpOptions)
			.pipe(
				map((bloodType) => {
					return bloodType;
				})
			);
	}

	deleteBloodType(bloodType: BloodType): Observable<BloodType> {
		return this.http
			.post<BloodType>(this.deleteBloodTypeUrl, bloodType, this.httpOptions)
			.pipe(
				map((bloodType) => {
					return bloodType;
				})
			);
	}
}
