<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt="" style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Edit Article</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="row ">
		            <div class="col-xl-12">
                        <div class="card card-xl-stretch mb-5 mb-xl-8">
                        <div class="card-body">
                            <div class="card-toolbar">
                                    <form [formGroup]="updateArticleForm" (ngSubmit)="updateArticle($event)" class="form">
                                        <div class="d-flex flex-column me-n7 pe-7">
                                            <div class="row mb-5">
                                                <div class="col-md-9 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Title</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="title"  placeholder="Title" (change)="onTitleChange($event)" />
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <label class="required fs-12 fw-semibold mb-2">Category Id</label>
                                                    <select class="form-control form-control-solid" formControlName="category_id">
                                                        <option value="">Please select category</option>
                                                        <option *ngFor="let category of categoryList;let i = index" [value]="category.id">{{category.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-9 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Slug</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="slug"  placeholder="Slug" />
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Featured Image</label>
                                                    <input type="file" #imageInput (change)="processFile(imageInput)" accept="image/*" class="form-control form-control-solid" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-9 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Description</label>
                                                    <input type="hidden" formControlName="edit_description_content" id="edit_description_content"/>
                                                    <textarea formControlName="edit_description" id="edit_description" class="form-control form-control-solid"></textarea>
                                                </div>
                                                <div class="col-md-3 fv-row">
                                                    <div class="row mb-5">
                                                        <div class="col-md-12 fv-row mb-5">
                                                            <label class="fs-5 fw-semibold mb-2">Current Image</label>
                                                            <img src="{{currentImage}}" height="200" />   <br> <br> <br>
                                                            <label class="required fs-5 fw-semibold mb-2">Uploaded By</label>
                                                            <select class="form-control form-control-solid" formControlName="uploaded_by">
                                                                <option value="">Please select user</option>
                                                                <option *ngFor="let user of userList;let i = index" [value]="user.id">
                                                                {{user.first_name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-12 fv-row mb-5">
                                                            <label class="required fs-5 fw-semibold mb-2">Status</label>
                                                            <select class="form-control form-control-solid" formControlName="status">
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-12 fv-row mb-5">
                                                            <label class="required fs-5 fw-semibold mb-2">Tags</label>
                                                            <input type="text" class="form-control form-control-solid" formControlName="tags" id="tags-add" placeholder="Tags" data-kt-repeater="tagify" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Title</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="meta_title"  placeholder="Meta Title" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Keywords</label>
                                                    <input type="text" class="form-control form-control-solid" formControlName="meta_keywords"  placeholder="Meta Keywords" />
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-md-12 fv-row">
                                                    <label class="required fs-5 fw-semibold mb-2">Meta Description</label>
                                                    <textarea style="height: 150px;" type="text" class="form-control form-control-solid" formControlName="meta_description"  placeholder="Meta Description"></textarea>
                                                </div>
                                            </div>
                                            <div *ngIf="errorMessage">
                                                <div class="alert alert-danger" role="alert">
                                                    {{errorMessage}}
                                                </div>
                                            </div>
                                            <div *ngIf="successMessage">
                                                <div class="alert alert-success" role="alert">
                                                    {{successMessage}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center pt-15">
                                            <a href="article_manage" class="btn btn-light me-3">Cancel</a>&nbsp;
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
			        </div>
			    </div>
			</div>
		</div>
	</div>
</div>