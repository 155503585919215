import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { CommunityService } from "../services/community.service";


@Component({
	selector: "app-community-list",
	templateUrl: "./community-list.component.html",
	styleUrls: ["./community-list.component.css"],
})
export class CommunityListComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	communityList: any;

	constructor(
		private communityService: CommunityService,
	) { }

	ngOnInit(): void {
		this.getCommunity();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getCommunity(): void {
		this.communityList = [];

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.communityService.getCommunity(dataTablesParameters).subscribe(
					(data) => {
						this.communityList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}
}
