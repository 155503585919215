import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { HospitalTypesModel } from "../models/hospitalTypes";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class HospitalTypesService {

	private getHospitalUrl = environment.baseURL + "hospitalType/getHospitalType";
	private getHospitalByIdUrl = environment.baseURL + "hospitalType/getHospitalTypeById";
	private updateHospitalUrl = environment.baseURL + "hospitalType/updateHospitalType";
	private addHospitalUrl = environment.baseURL + "hospitalType/createHospitalType";
	private deleteHospitalUrl = environment.baseURL + "hospitalType/deleteHospitalType";



	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getHospitalApi(params: any): Observable<HospitalTypesModel> {
		return this.http
			.post<HospitalTypesModel>(this.getHospitalUrl, params, this.httpOptions)
			.pipe(
				map((HospitalList) => {
					return HospitalList;
				})
			);
	}

	getHospitalById(hospitalTypeId: any): Observable<HospitalTypesModel> {
		let hospitalIds = { hospitalTypeId: hospitalTypeId };
		return this.http
			.post<HospitalTypesModel>(this.getHospitalByIdUrl, hospitalIds, this.httpOptions)
			.pipe(
				map((HospitalList) => {
					return HospitalList;
				})
			);
	}

	updateHospital(hospitalId: HospitalTypesModel): Observable<HospitalTypesModel> {
		return this.http
			.post<HospitalTypesModel>(this.updateHospitalUrl, hospitalId, this.httpOptions)
			.pipe(
				map((HospitalManage) => {
					return HospitalManage;
				})
			);
	}

	addHospitalApi(role_id: HospitalTypesModel): Observable<HospitalTypesModel> {
		return this.http
			.post<HospitalTypesModel>(this.addHospitalUrl, role_id, this.httpOptions)
			.pipe(
				map((HospitalManage) => {
					return HospitalManage;
				})
			);
	}

	deleteHospitalTypes(hospitalId: HospitalTypesModel): Observable<HospitalTypesModel> {
		return this.http
			.post<HospitalTypesModel>(this.deleteHospitalUrl, hospitalId, this.httpOptions)
			.pipe(
				map((Manage) => {
					return Manage;
				})
			);
	}

}
