import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: [],
})

export class SidebarComponent implements OnInit {

	role_id: any;

	constructor(private router: ActivatedRoute) {
		let currentUser: any = localStorage.getItem('currentUser');
		let userObject = JSON.parse(currentUser);
		if (currentUser) {
			this.role_id = userObject.data.role_id;
		}
	}

	ngOnInit() { }
}
