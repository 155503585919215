import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [

	]
})
export class LoginComponent implements OnInit {

	loginForm = new FormGroup({
		email: new FormControl('', [Validators.required]),
		password: new FormControl('', [Validators.required]),
	});

	get f() {
		return this.loginForm.controls;
	}

	users: User[] = [];
	errorMessage = '';
	successMessage = '';


	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
	) { }

	ngOnInit(): void {
	}

	login(): void {
		let email = this.loginForm.value.email.trim();
		let password = this.loginForm.value.password.trim();
		if (!email || !password) {
			this.errorMessage = 'All fields are required.';
			return;
		}
		this.authenticationService.login({ email, password } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						if (data.data.role_type == '1') {
							this.successMessage = data.message;
							this.users.push(data);
							setTimeout(() => {
								this.router.navigate(['dashboard']);
							}, 2000);
						} else {
							this.errorMessage = 'No routing found for this Role.';
						}
					} else {
						this.errorMessage = data.message;
					}
				},
				error => {
					this.errorMessage = error;
				}
			);
	}
}
