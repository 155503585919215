import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { UserListRoutingModule } from "./user-list-routing.module";
import { UserListComponent } from "./user-list.component";

@NgModule({
  imports: [
    CommonModule,
    UserListRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [UserListComponent],
  providers: [AuthGuard],
})
export class UserListModule { }
