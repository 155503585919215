import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Article } from "../models/article";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class ArticleService {

	private getArticleUrl = environment.baseURL + "article/getArticle";
	private addArticleUrl = environment.baseURL + "article/createArticle";
	private getArticleByIdUrl = environment.baseURL + "article/getArticleById";
	private uploadArticleImageUrl = environment.baseURL + "article/uploadArticleImage";
	private updateArticleUrl = environment.baseURL + "article/updateArticle";
	private deleteArticleUrl = environment.baseURL + "article/deleteArticle";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getArticle(params: any): Observable<Article> {
		return this.http
			.post<Article>(this.getArticleUrl, params, this.httpOptions)
			.pipe(
				map((articleList) => {
					return articleList;
				})
			);
	}

	addArticle(params: Article): Observable<Article> {
		return this.http
			.post<Article>(this.addArticleUrl, params, this.httpOptions)
			.pipe(
				map((articleList) => {
					return articleList;
				})
			);
	}

	getArticleById(articleId: any): Observable<Article> {
		let articleIds = { articleId: articleId };
		return this.http
			.post<Article>(this.getArticleByIdUrl, articleIds, this.httpOptions)
			.pipe(
				map((articleList) => {
					return articleList;
				})
			);
	}

	uploadArticleImage(image: File): Observable<any> {
		const formData = new FormData();
		formData.append('featured_image', image);
		return this.http.post(this.uploadArticleImageUrl, formData);
	}
	
	updateArticle(params: Article): Observable<Article> {
		return this.http
			.post<Article>(this.updateArticleUrl, params, this.httpOptions)
			.pipe(
				map((articleManage) => {
					return articleManage;
				})
			);
	}

	deleteArticle(articleId: Article): Observable<Article> {
		return this.http
			.post<Article>(this.deleteArticleUrl, articleId, this.httpOptions)
			.pipe(
				map((articleManage) => {
					return articleManage;
				})
			);
	}
}