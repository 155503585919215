import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BloodRequest } from "../models/bloodRequest";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class BloodRequestService {

	private getBloodRequestUrl = environment.baseURL + "bloodRequest/getBloodRequest";

	private getBloodRequestByUserIdUrl = environment.baseURL + "bloodRequest/getBloodRequestByUserId";

	private getBloodRequestByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestById";

	private getDonorListByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestDonorsById";

	private getDonorRatingReviewByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestReviewsById";

	private getDonorChatByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestChatById";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getBloodRequest(params: any): Observable<BloodRequest> {
		return this.http
			.post<BloodRequest>(this.getBloodRequestUrl, params, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}

	getBloodRequestByUserId(userId: any): Observable<BloodRequest> {
		let userIds = { userId: userId };

		return this.http
			.post<BloodRequest>(this.getBloodRequestByUserIdUrl, userIds, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}

	getBloodRequestById(bloodRequestId: any): Observable<BloodRequest> {
		let bloodRequestIds = { bloodRequestId: bloodRequestId };

		return this.http
			.post<BloodRequest>(this.getBloodRequestByIdUrl, bloodRequestIds, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}

	getDonorList(request_id: any): Observable<BloodRequest> {
		let bloodRequestIds = { request_id: request_id };
		return this.http
			.post<BloodRequest>(this.getDonorListByIdUrl, bloodRequestIds, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}

	getDonorRatingReview(request_id: any): Observable<BloodRequest> {
		let bloodRequestIds = { request_id: request_id };
		return this.http
			.post<BloodRequest>(this.getDonorRatingReviewByIdUrl, bloodRequestIds, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}

	getDonorChatApi(request_id: any): Observable<BloodRequest> {
		let bloodRequestIds = { request_id: request_id };
		return this.http
			.post<BloodRequest>(this.getDonorChatByIdUrl, bloodRequestIds, this.httpOptions)
			.pipe(
				map((bloodRequestList) => {
					return bloodRequestList;
				})
			);
	}
}
