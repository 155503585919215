import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ContactUsService } from "../services/contactUs.service";

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	contactList: any;
	contactById: any;

	constructor(
		private contactService: ContactUsService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getContact();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getContact(): void {
		this.contactList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.contactService.getContact(dataTablesParameters).subscribe(
					(data) => {
						that.contactList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openContactView(content: any, contactId: any): void {
		this.contactService.getContactById(contactId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.contactById = data.data;
				} else {
					this.contactById = [];
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}