<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Version Control Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card">
					<div class="card-header border-0 pt-6">
						<div class="card-title"></div>
						<div class="card-toolbar">
							<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
								<button type="button" class="btn btn-primary"
									(click)="openVersionControlAdd(addVersionControlContent)">
									<i class="ki-duotone ki-plus fs-2"></i> Add Version Control
								</button>
							</div>
							<ng-template #addVersionControlContent let-modal>
								<div class="modal-header" id="modal-basic-title">
									<h2 class="fw-bold">Add Version Control</h2>
									<div class="btn btn-icon btn-sm btn-active-icon-primary"
										data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
										<i class="ki-duotone ki-cross fs-1">
											<span class="path1"></span>
											<span class="path2"></span>
										</i>
									</div>
								</div>
								<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
									<form [formGroup]="addVersionControlForm" (ngSubmit)="addVersionControl($event)"
										class="form">
										<div class="d-flex flex-column scroll-y me-n7 pe-7">
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Version Name</label>
													<input type="text" class="form-control form-control-solid"
														formControlName="version_name" placeholder="Version Name"
														[ngClass]="{'inputError': f.version_name.touched && f.version_name.invalid && f.version_name.errors && f.version_name.errors.required }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Version Code</label>
													<input type="text" class="form-control form-control-solid"
														formControlName="version_code" placeholder="Version Code"
														[ngClass]="{'inputError': f.version_code.touched && f.version_code.invalid && f.version_code.errors && f.version_code.errors.required }" />
												</div>

											</div>
											<div class="row mb-5">
												<div class="col-md-12 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Description</label>
													<input type="text" class="form-control form-control-solid"
														formControlName="description" placeholder="Description"
														[ngClass]="{'inputError': f.description.touched && f.description.invalid && f.description.errors && f.description.errors.required }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Force Update</label>
													<input type="text" class="form-control form-control-solid"
														formControlName="force_update" placeholder="Force Update"
														[ngClass]="{'inputError': f.force_update.touched && f.force_update.invalid && f.force_update.errors && f.force_update.errors.required }" />
												</div>
												<div class="col-md-6 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Recommended
														Update</label>
													<input type="text" class="form-control form-control-solid"
														formControlName="recommended_update"
														placeholder="Recommended Update"
														[ngClass]="{'inputError': f.recommended_update.touched && f.recommended_update.invalid && f.recommended_update.errors && f.recommended_update.errors.required }" />
												</div>
											</div>
											<div class="row mb-5">
												<div class="col-md-12 fv-row">
													<label class="required fs-5 fw-semibold mb-2">Status</label>
													<select class="form-control form-control-solid"
														formControlName="status"
														[ngClass]="{'inputError': f.status.touched && f.status.invalid && f.status.errors && f.status.errors.required }">
														<option value="" disabled="disabled" selected="selected">Please
															select Status</option>
														<option value="1">Active</option>
														<option value="0">Inactive</option>
													</select>
												</div>
											</div>
											<div *ngIf="errorMessage">
												<div class="alert alert-danger" role="alert">
													{{errorMessage}}
												</div>
											</div>
											<div *ngIf="successMessage">
												<div class="alert alert-success" role="alert">
													{{successMessage}}
												</div>
											</div>
										</div>
										<div class="text-center pt-15">
											<button type="button" (click)="modal.dismiss('Cross click')"
												class="btn btn-light me-3"
												data-kt-users-modal-action="cancel">Cancel</button>
											<button type="submit" class="btn btn-primary"
												data-kt-users-modal-action="submit">
												<span class="indicator-label">Submit</span>
												<span class="indicator-progress">Please wait...
													<span
														class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
									</form>
								</div>
							</ng-template>
						</div>
					</div>
					<div class="card-body py-4">
						<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions">
							<thead>
								<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
									<th>SR. No.</th>
									<th>Version Name</th>
									<th>Version code</th>
									<th>Force Update</th>
									<th>Recommended Update</th>
									<th>Status</th>
									<th>Created</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody class="text-gray-600 fw-semibold" *ngIf="versionControlList?.length != 0">
								<tr *ngFor="let versionControl of versionControlList;let i = index">
									<td>{{i+1}}</td>
									<td>{{versionControl.version_name}}</td>
									<td>{{versionControl.version_code}}</td>
									<td>{{versionControl.force_update}}</td>
									<td>{{versionControl.recommended_update}}</td>
									<td>{{versionControl.status == '1' ? 'Active' : 'Inactive'}}</td>
									<td>{{versionControl.created | date :'medium'}}</td>
									<td>
										<a href="javascript:;"
											(click)="openVersionControlView(viewVersionControlContent, versionControl.id)"
											class="btn btn-sm btn-icon btn-active-color-primary">
											<i class="ki-outline ki-eye fs-2 text-primary">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a>
										<a href="javascript:;"
											(click)="openVersionControlEdit(updateVersionControlContent, versionControl.id)"
											class="btn btn-sm btn-icon btn-active-color-info">
											<i class="ki-outline ki-notepad-edit fs-2 text-info">
												<span class="path1"></span>
												<span class="path2"></span>
											</i>
										</a>
									</td>
								</tr>
							</tbody>
							<tbody class="text-gray-600 fw-semibold" *ngIf="versionControlList?.length == 0">
								<tr>
									<td>No Record Found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #updateVersionControlContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">Edit Version Control</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
			(click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
		<form [formGroup]="updateVersionControlForm" (ngSubmit)="updateVersionControl($event)" class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7">
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Version Name</label>
						<input type="text" class="form-control form-control-solid" formControlName="version_name"
							placeholder="Version Name"
							[ngClass]="{'inputError': f.version_name.touched && f.version_name.invalid && f.version_name.errors && f.version_name.errors.required }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Version Code</label>
						<input type="text" class="form-control form-control-solid" formControlName="version_code"
							placeholder="Version Code"
							[ngClass]="{'inputError': f.version_code.touched && f.version_code.invalid && f.version_code.errors && f.version_code.errors.required }" />
					</div>

				</div>
				<div class="row mb-5">
					<div class="col-md-12 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Description</label>
						<input type="text" class="form-control form-control-solid" formControlName="description"
							placeholder="Description"
							[ngClass]="{'inputError': f.description.touched && f.description.invalid && f.description.errors && f.description.errors.required }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Force Update</label>
						<input type="text" class="form-control form-control-solid" formControlName="force_update"
							placeholder="Force Update"
							[ngClass]="{'inputError': f.force_update.touched && f.force_update.invalid && f.force_update.errors && f.force_update.errors.required }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Recommended Update</label>
						<input type="text" class="form-control form-control-solid" formControlName="recommended_update"
							placeholder="Recommended Update"
							[ngClass]="{'inputError': f.recommended_update.touched && f.recommended_update.invalid && f.recommended_update.errors && f.recommended_update.errors.required }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-12 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Status</label>
						<select class="form-control form-control-solid" formControlName="status"
							[ngClass]="{'inputError': f.status.touched && f.status.invalid && f.status.errors && f.status.errors.required }">
							<option value="" disabled="disabled" selected="selected">Please select Status</option>
							<option value="1">Active</option>
							<option value="0">Inactive</option>
						</select>
					</div>
				</div>
				<div *ngIf="errorMessage">
					<div class="alert alert-danger" role="alert">
						{{errorMessage}}
					</div>
				</div>
				<div *ngIf="successMessage">
					<div class="alert alert-success" role="alert">
						{{successMessage}}
					</div>
				</div>
			</div>
			<div class="text-center pt-15">
				<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3"
					data-kt-users-modal-action="cancel">Cancel</button>
				<button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
					<span class="indicator-label">Submit</span>
					<span class="indicator-progress">Please wait...
						<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewVersionControlContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">View Version Control</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
			(click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y">
		<form class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true"
				data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
				data-kt-scroll-dependencies="#kt_modal_add_user_header"
				data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				<div class="table-responsive">
					<table class="table table-striped" style="line-height: 50px;;">
						<tr>
							<th class="text-right">Version Name</th>
							<td>{{versionControlById.version_name}}</td>
						</tr>
						<tr>
							<th class="text-right">Version Code</th>
							<td>{{versionControlById.version_code}}</td>
						</tr>
						<tr>
							<th class="text-right">Description</th>
							<td>{{versionControlById.description}}</td>
						</tr>
						<tr>
							<th class="text-right">Force Update</th>
							<td>{{versionControlById.force_update}}</td>
						</tr>
						<tr>
							<th class="text-right">Recommended Update</th>
							<td>{{versionControlById.recommended_update}}</td>
						</tr>
						<tr>
							<th class="text-right">Status</th>
							<td>{{versionControlById.status == '1' ? 'Active' : 'Inactive'}}</td>
						</tr>
						<tr>
							<th class="text-right">Created</th>
							<td>{{versionControlById.created | date : "medium"}}</td>
						</tr>
					</table>
					<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3"
						data-kt-users-modal-action="cancel">Cancel</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
