import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: "app-blood-request-list",
	templateUrl: "./blood-request-list.component.html",
	styleUrls: ["./blood-request-list.component.css"],
})
export class BloodRequestListComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	bloodRequestList: any;

	constructor(
		private bloodRequestService: BloodRequestService,
	) { }

	ngOnInit(): void {
		this.getBloodRequest();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}

	getBloodRequest(): void {
		this.bloodRequestList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bloodRequestService.getBloodRequest(dataTablesParameters).subscribe(
					(data) => {
						that.bloodRequestList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "patient_name" }, { data: "created" }],
		};
	}
}