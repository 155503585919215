import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { BusinessMasterService } from "../services/businessMaster.service";

@Component({
    selector: 'app-business-master',
    templateUrl: './business-master.component.html',
    styleUrls: ['./business-master.component.css']
})

export class BusinessMasterComponent implements OnInit {

    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    isDtInitialized:boolean = false;

    businessMasterList: any;

    constructor(
        private BusinessMasterService: BusinessMasterService,
    ) { }

    ngOnInit(): void {
        this.getBusinessMaster();
    }

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
    getBusinessMaster(): void {
        this.businessMasterList = [];
        const that = this;

        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
            this.BusinessMasterService.getBusinessMasterAdmin(dataTablesParameters).subscribe(
                (data) => {
                that.businessMasterList = data.data;
                callback({
                    recordsTotal: data.recordsTotal,
                    recordsFiltered: data.recordsFiltered,
                    data: [],
                });
                }
            );
            },
        };
    }
}