import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { UserService } from "../services/user.service";

@Component({
	selector: "app-user-list",
	templateUrl: "./user-list.component.html",
	styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
	dtOptions: DataTables.Settings = {};

	userList: any;
	role_type: any;

	constructor(
		private userService: UserService,
		private route: ActivatedRoute

	) { }

	ngOnInit(): void {
		this.getUser();
	}

	getUser(): void {
		this.role_type = this.route.snapshot.queryParamMap.get('role_type');
		this.userList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {

				this.userService.getAllUser(dataTablesParameters, this.role_type).subscribe(
					(data) => {
						that.userList = data.data;

						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
			columns: [{ data: "_id" }, { data: "patient_name" }, { data: "created" }],
		};
	}
}
