import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { EmailTemplate } from "../models/emailTemplate";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class EmailTemplateService {

	private getEmailTemplateUrl = environment.baseURL + "emailTemplate/getEmailTemplate";

	private getEmailTemplateByIdUrl = environment.baseURL + "emailTemplate/getEmailTemplateById";

	private addEmailTemplateUrl = environment.baseURL + "emailTemplate/createEmailTemplate";

	private updateEmailTemplateUrl = environment.baseURL + "emailTemplate/updateEmailTemplate";

	private deleteEmailTemplateUrl = environment.baseURL + "emailTemplate/deleteEmailTemplate";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getEmailTemplate(params: any): Observable<EmailTemplate> {
		return this.http
			.post<EmailTemplate>(this.getEmailTemplateUrl, params, this.httpOptions)
			.pipe(
				map((EmailTemplateList) => {
					return EmailTemplateList;
				})
			);
	}

	getEmailTemplateById(emailTemplateId: any): Observable<EmailTemplate> {
		let emailTemplateIds = { emailTemplateId: emailTemplateId };
		return this.http
			.post<EmailTemplate>(this.getEmailTemplateByIdUrl, emailTemplateIds, this.httpOptions)
			.pipe(
				map((emailTemplateList) => {
					return emailTemplateList;
				})
			);
	}

	addEmailTemplate(EmailTemplate: EmailTemplate): Observable<EmailTemplate> {
		return this.http
			.post<EmailTemplate>(this.addEmailTemplateUrl, EmailTemplate, this.httpOptions)
			.pipe(
				map((EmailTemplate) => {
					return EmailTemplate;
				})
			);
	}

	updateEmailTemplate(EmailTemplate: EmailTemplate): Observable<EmailTemplate> {
		return this.http
			.post<EmailTemplate>(this.updateEmailTemplateUrl, EmailTemplate, this.httpOptions)
			.pipe(
				map((versionControl) => {
					return versionControl;
				})
			);
	}

	deleteEmailTemplate(EmailTemplate: EmailTemplate): Observable<EmailTemplate> {
		return this.http
			.post<EmailTemplate>(this.deleteEmailTemplateUrl, EmailTemplate, this.httpOptions)
			.pipe(
				map((EmailTemplate) => {
					return EmailTemplate;
				})
			);
	}
}
