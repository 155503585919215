import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { ArticleCategoryService } from "../services/articleCategory.service";
import { ArticleCategory } from "../models/articleCategory";

@Component({
	selector: 'app-article-category',
	templateUrl: './article-category.component.html',
	styleUrls: ['./article-category.component.css']
})

export class ArticleCategoryComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;

	closeResult = "";
	articleCategoryList: any;
	deleteArticleCategoryId: any;

	errorMessage = "";
	successMessage = "";
	
	addArticleCategoryForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateArticleCategoryForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		articleCategoryId: new FormControl("", [Validators.required]),
	});

	constructor(
		private articleCategoryService: ArticleCategoryService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getArticleCategory();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getArticleCategory(): void {
		this.articleCategoryList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.articleCategoryService.getArticleCategory(dataTablesParameters).subscribe(
					(data) => {
						that.articleCategoryList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "role_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openArticleCategoryAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addArticleCategory($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let name = this.addArticleCategoryForm.value.name.trim();
		let status = this.addArticleCategoryForm.value.status.trim();
		if (
			!name ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.articleCategoryService
			.addArticleCategory({
				name,
				status
			} as ArticleCategory)
			.subscribe(
				(data) => {
					if (data.status == 200) {
						this.errorMessage = '';
						this.addArticleCategoryForm.reset(
							{
								"name": "",
								"status": ""
							}
						);
						document.getElementById("addArticleCategoryModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openArticleCategoryEdit(content: any, articleCategoryId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.articleCategoryService.getArticleCategoryById(articleCategoryId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateArticleCategoryForm.controls["name"].setValue(data.data.name);
					this.updateArticleCategoryForm.controls["status"].setValue(data.data.status);
					this.updateArticleCategoryForm.controls["articleCategoryId"].setValue(data.data.id);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	updateArticleCategory($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let articleCategoryId = this.updateArticleCategoryForm.value.articleCategoryId;
		let name = this.updateArticleCategoryForm.value.name.trim();
		let status = this.updateArticleCategoryForm.value.status;

		if (
			!name ||
			!status ||
			!articleCategoryId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.articleCategoryService
			.updateArticleCategory({
				name,
				status,
				articleCategoryId
			} as ArticleCategory)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateArticleCategoryForm.reset(
							{
								"name": "",
								"status": "",
								"articleCategoryId": ""
							}
						);
						document.getElementById("updateArticleCategoryModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openArticleCategoryDelete(content: any, articleCategoryId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.deleteArticleCategoryId = articleCategoryId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}
	
	deleteArticleCategory(): void {
		let articleCategoryId = this.deleteArticleCategoryId
		if (!articleCategoryId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.articleCategoryService.deleteArticleCategory({ articleCategoryId } as ArticleCategory).subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteArticleCategoryModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getArticleCategory();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}