import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AmenityManageComponent } from './amenity-manage.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'amenity_manage',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: AmenityManageComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmenityManageRoutingModule { }
