import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { BloodTypeService } from "../services/bloodType.service";
import { BloodType } from "../models/bloodType";

@Component({
	selector: "app-blood-type-list",
	templateUrl: "./blood-type-list.component.html",
	styleUrls: ["./blood-type-list.component.css"],
})
export class BloodTypeListComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	addBloodTypeForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateBloodTypeForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		bloodTypeId: new FormControl("", [Validators.required]),
	});

	bloodTypeList: any;
	deleteBloodId: any;

	errorMessage = "";
	successMessage = "";

	constructor(
		private bloodTypeService: BloodTypeService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getBloodType();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}

	getBloodType(): void {
		this.bloodTypeList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.bloodTypeService.getBloodType(dataTablesParameters).subscribe(
					(data) => {
						that.bloodTypeList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openBloodTypeAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addBloodType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.addBloodTypeForm.value.name.trim();
		let status = this.addBloodTypeForm.value.status;
		if (
			!name ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.bloodTypeService
			.addBloodType({
				name,
				status
			} as BloodType)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.addBloodTypeForm.reset(
							{
								"name": "",
								"status": ""
							}
						);
						document.getElementById("addBloodTypeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openBloodTypeEdit(content: any, bloodTypeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.bloodTypeService.getBloodTypeById(bloodTypeId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateBloodTypeForm.controls["name"].setValue(data.data.name);
					this.updateBloodTypeForm.controls["status"].setValue(data.data.status);
					this.updateBloodTypeForm.controls["bloodTypeId"].setValue(data.data.id);
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	updateBloodType($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.updateBloodTypeForm.value.name.trim();
		let status = this.updateBloodTypeForm.value.status;
		let bloodTypeId = this.updateBloodTypeForm.value.bloodTypeId;

		if (
			!name ||
			!bloodTypeId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.bloodTypeService
			.updateBloodType({
				name,
				status,
				bloodTypeId
			} as BloodType)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateBloodTypeForm.reset(
							{
								"name": "",
								"status": "",
								"bloodTypeId": ""
							}
						);
						document.getElementById("updateBloodTypeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openBloodTypeDelete(content: any, bloodTypeId: any): void {
		this.deleteBloodId = bloodTypeId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteBloodType(): void {
		let bloodTypeId = this.deleteBloodId;
		if (!bloodTypeId) {
			this.errorMessage = "Something wrong happen.";
			return;
		}
		this.bloodTypeService.deleteBloodType({ bloodTypeId } as BloodType).subscribe(
			(data) => {
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteBloodTypeModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessage = data.message;
				}
			},
			(error) => {
				this.errorMessage = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getBloodType();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}
