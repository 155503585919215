import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { NewslatterManageModel } from "../models/newslatterManage";
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class NewslatterManageService {


	private getNewsUrl = environment.baseURL + "newsletter/getNewsletter";



	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }




	getNewsApi(params: any): Observable<NewslatterManageModel> {
		return this.http
			.post<NewslatterManageModel>(this.getNewsUrl, params, this.httpOptions)
			.pipe(
				map((List) => {
					return List;
				})
			);
	}
}
