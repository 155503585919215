import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { UserService } from "../services/user.service";
import { BloodRequestService } from "../services/bloodRequest.service";
import { AppointmentService } from "../services/appointment.service";
import { CommonService } from "../services/common.service";
import { BadgeService } from "../services/badge.service"

@Component({
	selector: "app-user-view",
	templateUrl: "./user-view.component.html",
	styleUrls: ["./user-view.component.css"],
})
export class UserViewComponent implements OnInit {

	userId: any;
	userById: any;


	constructor(
		private userService: UserService,
		private bloodRequestService: BloodRequestService,
		private appointmentService: AppointmentService,
		private commonService: CommonService,
		private badgeService: BadgeService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getUserById(this.userId);
	}

	overviewData = true;
	bloodRequest = false;
	appointments = false;
	device = false;
	badge = false;
	favourite = false;
	loginTimeline = false;
	notification = false;
	referral = false;
	settings = false;

	getUserById(userId: any): void {
		this.userService.getUserById(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = true;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;

				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getBloodRequestByUserId(userId: any): void {
		this.bloodRequestService.getBloodRequestByUserId(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = true;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}
	getAppointmentsByUserId(userId: any): void {
		this.appointmentService.getAppointmentsByUserId(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = true;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;

				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getDeviceDetailsByUserId(user_id: any): void {
		this.commonService.getDeviceById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = true;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getBadgeDataByUserId(user_id: any): void {
		this.badgeService.getBadgeHistoryById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = true;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getFavouriteDataByUserId(user_id: any): void {
		this.userService.getFavouriteById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = true;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getLoginLogByUserId(user_id: any): void {
		this.userService.getLoginById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = true;
					this.notification = false;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getNotificationByUserId(user_id: any): void {
		this.userService.getNotificationById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = true;
					this.referral = false;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getReferralByUserId(user_id: any): void {
		this.userService.getReferralById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = true;
					this.settings = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	getSettingsByUserId(user_id: any): void {
		this.commonService.getSettingsById(user_id).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.bloodRequest = false;
					this.appointments = false;
					this.device = false;
					this.badge = false;
					this.favourite = false;
					this.loginTimeline = false;
					this.notification = false;
					this.referral = false;
					this.settings = true;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

}
