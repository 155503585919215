import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ArticleCategory } from "../models/articleCategory";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class ArticleCategoryService {

	private getArticleCategoryUrl = environment.baseURL + "articleCategory/getArticleCategory";
	private addArticleCategoryUrl = environment.baseURL + "articleCategory/createArticleCategory";
	private getArticleCategoryByIdUrl = environment.baseURL + "articleCategory/getArticleCategoryById";
	private updateArticleCategoryUrl = environment.baseURL + "articleCategory/updateArticleCategory";
	private deleteArticleCategoryUrl = environment.baseURL + "articleCategory/deleteArticleCategory";
	private getAllArticleCategoryUrl = environment.baseURL + "articleCategory/getAllArticleCategory";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getArticleCategory(params: any): Observable<ArticleCategory> {
		return this.http
			.post<ArticleCategory>(this.getArticleCategoryUrl, params, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}

	addArticleCategory(role_id: ArticleCategory): Observable<ArticleCategory> {
		return this.http
			.post<ArticleCategory>(this.addArticleCategoryUrl, role_id, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}

	getArticleCategoryById(articleCategoryId: any): Observable<ArticleCategory> {
		let articleIds = { articleCategoryId: articleCategoryId };
		return this.http
			.post<ArticleCategory>(this.getArticleCategoryByIdUrl, articleIds, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}

	updateArticleCategory(articleCategoryId: ArticleCategory): Observable<ArticleCategory> {
		return this.http
			.post<ArticleCategory>(this.updateArticleCategoryUrl, articleCategoryId, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}

	deleteArticleCategory(articleCategoryId: ArticleCategory): Observable<ArticleCategory> {
		return this.http
			.post<ArticleCategory>(this.deleteArticleCategoryUrl, articleCategoryId, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}

	getAllArticleCategory(): Observable<ArticleCategory> {
		return this.http
			.get<ArticleCategory>(this.getAllArticleCategoryUrl, this.httpOptions)
			.pipe(
				map((articleCategoryList) => {
					return articleCategoryList;
				})
			);
	}
}