import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthGuard } from "../auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";

import { BusinessMasterDetailsRoutingModule } from "./business-master-details-routing.module";
import { BusinessMasterDetailsComponent } from "./business-master-details.component";

@NgModule({
  imports: [
    CommonModule,
    BusinessMasterDetailsRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [BusinessMasterDetailsComponent],
  providers: [AuthGuard],
})
export class BusinessMasterDetailsModule { }
