import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { BadgeService } from "../services/badge.service";
import { Badge } from "../models/badge";

@Component({
	selector: "app-badge-list",
	templateUrl: "./badge-list.component.html",
	styleUrls: ["./badge-list.component.css"],
})
export class BadgeListComponent implements OnInit {

	@ViewChild(DataTableDirective, { static: false })
	dtElement!: DataTableDirective;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	isDtInitialized:boolean = false;
	
	closeResult = "";

	addBadgeForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		no_of_user: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
	});

	updateBadgeForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		description: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		no_of_user: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		badgeId: new FormControl("", [Validators.required]),
	});

	badgeList: any;
	badgeById: any;
	deleteBadgeId: any;
	currentImage: any;

	images: string[] = [];

	errorMessage = "";
	errorMessageDelete = "";
	successMessage = "";
	successMessageDelete = "";

	constructor(
		private badgeService: BadgeService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.getBadge();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}
	
	getBadge(): void {
		this.badgeList = [];
		const that = this;

		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.badgeService.getBadge(dataTablesParameters).subscribe(
					(data) => {
						that.badgeList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					}
				);
			},
			columns: [{ data: "_id" }, { data: "name" }, { data: "created" }],
		};
	}

	openBadgeAdd(content: any): void {
		this.successMessage = "";
		this.errorMessage = "";

		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);

				this.addBadgeForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	addBadge($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let title = this.addBadgeForm.value.title.trim();
		let description = this.addBadgeForm.value.description.trim();
		let image = this.addBadgeForm.value.image;
		let no_of_user = this.addBadgeForm.value.no_of_user.trim();
		let status = this.addBadgeForm.value.status.trim();
		if (
			!title ||
			!description ||
			!image ||
			!no_of_user ||
			!status
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.badgeService
			.addBadge({
				title,
				description,
				image,
				no_of_user,
				status
			} as Badge)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.addBadgeForm.reset(
							{
								"title": "",
								"description": "",
								"no_of_user": "",
								"image": "",
								"status": ""
							}
						);
						document.getElementById("addBadgeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}
	
	openBadgeView(content: any, badgeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.badgeService.getBadgeById(badgeId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.badgeById = data.data;
				} else {
					this.badgeById = [];
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}
	
	openBadgeEdit(content: any, badgeId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.badgeService.getBadgeById(badgeId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.badgeById = data.data;
					this.updateBadgeForm.controls["title"].setValue(data.data.title);
					this.updateBadgeForm.controls["description"].setValue(data.data.description);
					this.updateBadgeForm.controls["image"].setValue(data.data.image);
					this.currentImage = data.data.image;
					this.updateBadgeForm.controls["no_of_user"].setValue(data.data.no_of_user);
					this.updateBadgeForm.controls["status"].setValue(data.data.status);
					this.updateBadgeForm.controls["badgeId"].setValue(data.data.id);
				} else {
					this.badgeById = [];
				}
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;
		if (file) {
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.images.push(event.target.result);
				this.updateBadgeForm.patchValue({
					image: this.images
				});
			}
			reader.readAsDataURL(imageInput.files[0]);
		}
	}

	updateBadge($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let title = this.updateBadgeForm.value.title;
		let description = this.updateBadgeForm.value.description.trim();
		let image = this.updateBadgeForm.value.image;
		let no_of_user = this.updateBadgeForm.value.no_of_user;
		let status = this.updateBadgeForm.value.status;
		let badgeId = this.updateBadgeForm.value.badgeId;
		
		if (
			!title ||
			!description ||
			!image ||
			!no_of_user ||
			!badgeId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.badgeService
			.updateBadge({
				title,
				description,
				image,
				no_of_user,
				status,
				badgeId
			} as Badge)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.errorMessage = '';
						this.updateBadgeForm.reset(
							{
								"name": "",
								"description": "",
								"no_of_user": "",
								"image": "",
								"status": "",
								"badgeId": ""
							}
						);
						document.getElementById("updateBadgeModalClose")?.click();
						this.successMessage = data.message;
						this.rerender();
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	openBadgeDelete(content: any, badgeId: any): void {
		this.deleteBadgeId = badgeId;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteBadge(): void {
		let badgeId = this.deleteBadgeId
		if (!badgeId) {
			this.errorMessageDelete = "Something wrong happen.";
			return;
		}
		this.badgeService.deleteBadge({ badgeId } as Badge).subscribe(
			(data) => {
				this.errorMessageDelete = "";
				if (data.status == 200) {
					this.errorMessage = '';
					document.getElementById("deleteBadgeModalClose")?.click();
					this.successMessage = data.message;
					this.rerender();
				} else {
					this.errorMessageDelete = data.message;
				}
			},
			(error) => {
				this.errorMessageDelete = error;
			}
		);
	}

	rerender(): void {
		this.dtElement.dtInstance.then(dtInstance => {
		  	dtInstance.destroy();
		  	this.getBadge();
		  	this.dtTrigger.next(null);
		  	setTimeout(() => {
				this.successMessage = '';
			}, 4000);
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}