import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { EmailTemplateService } from "../services/emailTemplate.service";
import { EmailTemplate } from "../models/emailTemplate";

@Component({
	selector: 'app-email-template-manage',
	templateUrl: './email-template-manage.component.html',
	styleUrls: ['./email-template-manage.component.css']
})
export class EmailTemplateManageComponent implements OnInit {

	dtOptions: DataTables.Settings = {};

	closeResult = "";

	addEmailTemplateForm = new FormGroup({
		subject: new FormControl("", [Validators.required]),
		alias: new FormControl("", [Validators.required]),
		message: new FormControl("", [Validators.required]),
	});

	updateemailTemplateForm = new FormGroup({
		subject: new FormControl("", [Validators.required]),
		alias: new FormControl("", [Validators.required]),
		message: new FormControl("", [Validators.required]),
		emailTemplateId: new FormControl("", [Validators.required]),
	});


	get f() {
		return this.addEmailTemplateForm.controls;
	}

	emailTemplateList: any;
	emailTemplateById: any;
	deleteEmailId: any;

	successMessagedelete = "";
	errorMessagedelete = "";
	errorMessage = "";
	successMessage = "";

	constructor(
		private EmailTemplateService: EmailTemplateService,
		private modalService: NgbModal
	) { }


	ngOnInit(): void {
		this.getEmailTemplate();
	}

	getEmailTemplate(): void {
		this.emailTemplateList = [];
		this.dtOptions = {
			pagingType: "full_numbers",
			pageLength: 10,
			serverSide: true,
			processing: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.EmailTemplateService.getEmailTemplate(dataTablesParameters).subscribe(
					(data) => {
						this.emailTemplateList = data.data;
						callback({
							recordsTotal: data.recordsTotal,
							recordsFiltered: data.recordsFiltered,
							data: [],
						});
					},
					(error) => {
					}
				);
			},
		};
	}
	openEmailTemplateAdd(content: any): void {
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	openEmailTemplateEdit(content: any, emailTemplatelId: any): void {
		this.EmailTemplateService.getEmailTemplateById(emailTemplatelId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateemailTemplateForm.controls["subject"].setValue(data.data.subject);
					this.updateemailTemplateForm.controls["alias"].setValue(data.data.alias);
					this.updateemailTemplateForm.controls["message"].setValue(data.data.message);
					this.updateemailTemplateForm.controls["emailTemplateId"].setValue(data.data.id);
				} else {
				}
			},
			(error) => {
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	openEmailTemplateView(content: any, emailTemplateId: any): void {
		this.EmailTemplateService.getEmailTemplateById(emailTemplateId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.emailTemplateById = data.data;
				} else {
					this.emailTemplateById = [];
				}
			},
			(error) => {
			}
		);
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: "lg", backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
	}

	addEmailTemplate($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let subject = this.addEmailTemplateForm.value.subject.trim();
		let alias = this.addEmailTemplateForm.value.alias.trim();
		let message = this.addEmailTemplateForm.value.message.trim();

		if (!subject || !alias || !message) {

			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;

			return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.EmailTemplateService
			.addEmailTemplate({
				subject,
				alias,
				message,
			} as EmailTemplate)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	updateEmailTemplate($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let subject = this.updateemailTemplateForm.value.subject.trim();
		let alias = this.updateemailTemplateForm.value.alias.trim();
		let message = this.updateemailTemplateForm.value.message.trim();
		let emailTemplateId = this.updateemailTemplateForm.value.emailTemplateId;

		if (!subject || !alias || !message) {

			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;

		this.EmailTemplateService
			.updateEmailTemplate({
				subject,
				alias,
				message,
				emailTemplateId
			} as EmailTemplate)
			.subscribe(
				(data) => {
					this.errorMessage = "";
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;

					}
				},
				(error) => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	deleteEmailConformation(content: any, Id: any): void {
		this.deleteEmailId = Id;
		this.modalService
			.open(content, { ariaLabelledBy: "modal-basic-title", size: '10', backdrop: 'static', keyboard: false })
			.result.then(
				(result) => {
					this.closeResult = `Closed with: ${result}`;
				},
				(reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);

	}
	
	deleteEmail(): void {

		let emailTemplateId = this.deleteEmailId
		if (!emailTemplateId) {
			this.errorMessagedelete = "Something wrong happen.";
			return;
		}
		this.EmailTemplateService.deleteEmailTemplate({ emailTemplateId } as EmailTemplate).subscribe(
			(data) => {
				this.errorMessagedelete = "";
				if (data.status == 200) {
					this.successMessagedelete = data.message;
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessagedelete = data.message;
				}
			},
			(error) => {
				this.errorMessagedelete = error;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return `with: ${reason}`;
		}
	}
}
