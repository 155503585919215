import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BusinessMaster } from "../models/businessMaster";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class BusinessMasterService {

	private getBusinessMasterUrl = environment.baseURL + "businessMaster/getBusinessMasterAdmin";

	private getBusinessMasterDetailsUrl = environment.baseURL + "businessMaster/getInfoByBusinessId";

	private getBusinessAmenityDetailsUrl = environment.baseURL + "amenity/getAmenityByBusinessId";

	private getBusinessDepartmentDetailsUrl = environment.baseURL + "department/getDepartmentByBusinessId";

	private getBusinessDoctorDetailsUrl = environment.baseURL + "doctor/getDoctorByBusinessId";

	private getBusinessImageDetailsUrl = environment.baseURL + "businessMaster/getGalleryImagesById";

	private getBusinessSlotDetailsUrl = environment.baseURL + "slotMaster/getSlotByBusinessId";

	private getBusinessRole = environment.baseURL + "businessMaster/getAllBusinessMasterAdmin";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getBusinessMasterAdmin(params: any): Observable<BusinessMaster> {
		return this.http
			.post<BusinessMaster>(this.getBusinessMasterUrl, params, this.httpOptions)
			.pipe(
				map((Businessmapreturn) => {
					return Businessmapreturn;
				})
			);
	}

	getBusinessDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessMasterDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessDetailsList) => {
					return BusinessDetailsList;
				})
			);
	}

	getAmenityDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessAmenityDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessDetailsList) => {
					return BusinessDetailsList;
				})
			);
	}

	getDepartmentDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessDepartmentDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessDetailsList) => {
					return BusinessDetailsList;
				})
			);
	}

	getDoctorDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessDoctorDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessDetailsList) => {
					return BusinessDetailsList;
				})
			);
	}

	getImageDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessImageDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessList) => {
					return BusinessList;
				})
			);
	}

	getBusinessSlotDetails(b_id: any): Observable<BusinessMaster> {
		let businessIds = { b_id: b_id };
		return this.http
			.post<BusinessMaster>(this.getBusinessSlotDetailsUrl, businessIds, this.httpOptions)
			.pipe(
				map((BusinessDetailsList) => {
					return BusinessDetailsList;
				})
			);
	}

	getBusinessId(): Observable<BusinessMaster> {
		return this.http
			.get<BusinessMaster>(this.getBusinessRole, this.httpOptions)
			.pipe(
				map((Get) => {
					return Get;
				})
			);
	}
}
