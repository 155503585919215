import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonService } from '../services/common.service';
import { User } from '../models/user';

@Component({
	selector: 'app-update-profile',
	templateUrl: './update-profile.component.html',
	styleUrls: [

	]
})
export class UpdateProfileComponent implements OnInit {

	UpdateProfileForm = new FormGroup({
		FullName: new FormControl("", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
		Email: new FormControl("", [Validators.required]),
		PhoneNumber: new FormControl("", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
		Gender: new FormControl("", [Validators.required]),

	});

	get f() {
		return this.UpdateProfileForm.controls;
	}

	users: User[] = [];
	errorMessage = '';
	successMessage = '';

	constructor(
		private CommonService: CommonService,
	) { }

	ngOnInit(): void {

	}

	UpdateProfile(): void {
		this.errorMessage = '';
		this.successMessage = '';
		let FullName = this.UpdateProfileForm.value.FullName.trim();
		let Email = this.UpdateProfileForm.value.Email.trim();
		let PhoneNumber = this.UpdateProfileForm.value.PhoneNumber.trim();
		let Gender = this.UpdateProfileForm.value.Gender.trim();

		if (!FullName || !Email || !PhoneNumber || !Gender) {

			this.errorMessage = 'All fields are required.';
			return;
		}

		// this.CommonService.updateProfile({ FullName, Email, PhoneNumber, Gender } as User)
		// 	.subscribe(
		// 		data => {
		// 			this.errorMessage = '';
		// 			if (data.status == 200) {
		// 				if (data.data.role_type == '1') {
		// 					this.successMessage = data.message;
		// 					this.users.push(data);
		// 					setTimeout(() => {

		// 					}, 2000);
		// 				} else if (data.data.role_type == '3') {
		// 					this.successMessage = data.message;
		// 					this.users.push(data);
		// 					setTimeout(() => {

		// 					}, 2000);
		// 				} else if (data.data.role_type == '4') {
		// 					this.successMessage = data.message;
		// 					this.users.push(data);
		// 					setTimeout(() => {

		// 					}, 2000);
		// 				} else if (data.data.role_type == '5') {
		// 					this.successMessage = data.message;
		// 					this.users.push(data);
		// 					setTimeout(() => {

		// 					}, 2000);
		// 				} else {
		// 					this.errorMessage = 'No routing found for this Role.';
		// 				}
		// 			} else {
		// 				this.errorMessage = data.message;
		// 			}
		// 		},
		// 		error => {
		// 			this.errorMessage = error;
		// 		}
		// 	);
	}
}	
