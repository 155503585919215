import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/user";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class UserService {

	private getAllAdminUrl = environment.baseURL + "user/getAllAdmin";

	private getAllUserUrl = environment.baseURL + "user/getAllUser";

	private getAllUserRoleUrl = environment.baseURL + "user/getAllUserRole";

	private getUserByIdUrl = environment.baseURL + "user/getUserById";

	private getFavouriteByIdUrl = environment.baseURL + "user/getFavouriteByUserId";

	private getLoginByIdUrl = environment.baseURL + "user/getLoginLogByUserId";

	private getNotifactionByIdUrl = environment.baseURL + "notification/getNotificationByUserId";

	private getReferralByIdUrl = environment.baseURL + "user/getReferralHistoryByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getAllUser(params: any, role_type: any): Observable<User> {
		params.role_type = role_type;

		return this.http
			.post<User>(this.getAllUserUrl, params, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}
	getUserById(userId: any): Observable<User> {
		let userIds = { userId: userId };
		return this.http
			.post<User>(this.getUserByIdUrl, userIds, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}

	getFavouriteById(userId: any): Observable<User> {
		let user_id = { user_id: userId };
		return this.http
			.post<User>(this.getFavouriteByIdUrl, user_id, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}
	getLoginById(userId: any): Observable<User> {
		let user_id = { user_id: userId };
		return this.http
			.post<User>(this.getLoginByIdUrl, user_id, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}
	getNotificationById(userId: any): Observable<User> {
		let user_id = { user_id: userId };
		return this.http
			.post<User>(this.getNotifactionByIdUrl, user_id, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}
	getReferralById(userId: any): Observable<User> {
		let user_id = { user_id: userId };
		return this.http
			.post<User>(this.getReferralByIdUrl, user_id, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}

	getAllAdmin(): Observable<User> {
		return this.http
			.get<User>(this.getAllAdminUrl, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}


	getAllUserRole(): Observable<User> {
		return this.http
			.get<User>(this.getAllUserRoleUrl, this.httpOptions)
			.pipe(
				map((userList) => {
					return userList;
				})
			);
	}
}
