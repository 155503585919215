<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Blood Request Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10">
					<div class="card-body pt-9 pb-0">
						<div class="d-flex flex-wrap flex-sm-nowrap">
						</div>
						<ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="BloodRequestById(userId)" [ngClass]="{ active: step === 1 }">Overview</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5" (click)="donorListView(userId)"
									[ngClass]="{ active: step === 2 }">Donor List</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									(click)="donorRatingReview (userId)" [ngClass]="{ active: step === 3 }">Rating&
									Review</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5" (click)="donorChat (userId)"
									[ngClass]="{ active: step === 4 }">chat</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10">
					<div class="card-body p-9" *ngIf="overviewData">
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">User Id</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.user_id}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Blood Type</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.blood_type}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Phone</label>
							<div class="col-lg-8 fv-row">
								<span class="fw-semibold text-gray-800 fs-6">{{userById.phone_number}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Patient Name</label>
							<div class="col-lg-8">
								<a href="javascript:;"
									class="fw-semibold fs-6 text-gray-800 text-hover-primary">{{userById.patient_name}}</a>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Blood Group</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.blood_group}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Required Date</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.required_date}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Units</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.units}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Location</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.location}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Critical Level</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.is_critical}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Notes</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.notes}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Status</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.status}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Document</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.document}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Latitude</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.latitude}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Longitude</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.longitude}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Date Filter</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.date_filter}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Created</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.created}}</span>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="blood_request_list" class="btn btn-primary">Back</a>
						</div>
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="donorData">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="card-body py-4">
									<table class="table align-middle table-row-dashed fs-6 gy-5">
										<thead>
											<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
												<th>ID.</th>
												<th>Donor Request Id</th>
												<th>Is Accepted</th>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Profile Picture</th>
											</tr>
										</thead>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length != 0">
											<tr *ngFor="let user of userById;">
												<td>{{user.id}}</td>
												<td>{{user.donor_request_id}}</td>
												<td>{{user.is_accepted}}</td>
												<td>{{user.first_name}}</td>
												<td>{{user.last_name}}</td>
												<td><img src="{{user.profile_picture}}" width="50" height="50" /></td>
												<td>{{user.created | date :'medium'}}</td>
											</tr>
										</tbody>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length == 0">
											<tr>
												<td>No Record Found</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="blood_request_list" class="btn btn-primary">Back</a>
						</div>
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="donorReview">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="card-body py-4">
									<table class="table align-middle table-row-dashed fs-6 gy-5">
										<thead>
											<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
												<th>ID.</th>
												<th>Request Id</th>
												<th>Donor Id</th>
												<th>Rating</th>
												<th>Comment</th>
												<th>review</th>
												<th>User Id</th>
												<th>Status</th>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Profile Picture</th>
												<th>Created</th>
											</tr>
										</thead>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length != 0">
											<tr *ngFor="let user of userById;">
												<td>{{user.id}}</td>
												<td>{{user.request_id}}</td>
												<td>{{user.donor_id}}</td>
												<td>{{user.rating}}</td>
												<td>{{user.comment}}</td>
												<td>{{user.review}}</td>
												<td>{{user.user_id}}</td>
												<td>{{user.status}}</td>
												<td>{{user.first_name}}</td>
												<td>{{user.last_name}}</td>
												<td><img src="{{user.profile_picture}}" width="50" height="50" /></td>
												<td>{{user.created | date :'medium'}}</td>
											</tr>
										</tbody>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length == 0">
											<tr>
												<td>No Record Found</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="blood_request_list" class="btn btn-primary">Back</a>
						</div>
					</div>
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" *ngIf="donorChatList">
						<div class="post d-flex flex-column-fluid" id="kt_post">
							<div id="kt_content_container" class="container-xxl">
								<div class="card-body py-4">
									<table class="table align-middle table-row-dashed fs-6 gy-5">
										<thead>
											<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
												<th>ID.</th>
												<th>Request Id</th>
												<th>Sent From</th>
												<th>Sent To</th>
												<th>Message</th>
												<th>Created</th>
											</tr>
										</thead>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length != 0">
											<tr *ngFor="let user of userById;">
												<td>{{user.id}}</td>
												<td>{{user.request_id}}</td>
												<td>{{user.sent_from}}</td>
												<td>{{user.sent_to}}</td>
												<td>{{user.message}}</td>
												<td>{{user.created | date :'medium'}}</td>
											</tr>
										</tbody>
										<tbody class="text-gray-600 fw-semibold" *ngIf="userById?.length == 0">
											<tr>
												<td>No Record Found</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="blood_request_list" class="btn btn-primary">Back</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
