<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Email Template Manage</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content"></div>
	<div class="post d-flex flex-column-fluid" id="kt_post">
		<div id="kt_content_container" class="container-xxl">
			<div class="card">
				<div class="card-header border-0 pt-6">
					<div class="card-title"></div>
					<div class="card-toolbar">
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<button type="button" class="btn btn-primary"
								(click)="openEmailTemplateAdd(addEmailTemplateContent)">
								<i class="ki-duotone ki-plus fs-2"></i> Add Email Template
							</button>
						</div>
						<ng-template #addEmailTemplateContent let-modal>
							<div class="modal-header" id="modal-basic-title">
								<h2 class="fw-bold">Add Email Template</h2>
								<div class="btn btn-icon btn-sm btn-active-icon-primary"
									data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
									<i class="ki-duotone ki-cross fs-1">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</div>
							</div>
							<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
								<form [formGroup]="addEmailTemplateForm" (ngSubmit)="addEmailTemplate($event)"
									class="form">
									<div class="d-flex flex-column scroll-y me-n7 pe-7">
										<div class="row mb-5">
											<div class="col-md-6 fv-row">
												<label class="required fs-5 fw-semibold mb-2">Subject</label>
												<input type="text" class="form-control form-control-solid"
													formControlName="subject" placeholder="Email Subject"
													[ngClass]="{'inputError': f.subject.touched && f.subject.invalid && f.subject.errors && f.subject.errors.required }" />
											</div>
											<div class="col-md-6 fv-row">
												<label class="required fs-5 fw-semibold mb-2">Alias</label>
												<input type="text" class="form-control form-control-solid"
													formControlName="alias" placeholder="Email Alias"
													[ngClass]="{'inputError': f.alias.touched && f.alias.invalid && f.alias.errors && f.alias.errors.required }" />
											</div>
										</div>
										<div class="row mb-5">
											<div class="col-md-12 fv-row">
												<label class="required fs-5 fw-semibold mb-2">Message</label>
												<textarea style="height: 100px; width: 500px; border-style:solid"
													type="text" class="form-control form-control-solid"
													formControlName="message" placeholder="Email Message"
													[ngClass]="{'inputError': f.message.touched && f.message.invalid && f.message.errors && f.message.errors.required }"></textarea>
											</div>
										</div>
										<div class="row mb-5">
										</div>
										<div *ngIf="errorMessage">
											<div class="alert alert-danger" role="alert">
												{{errorMessage}}
											</div>
										</div>
										<div *ngIf="successMessage">
											<div class="alert alert-success" role="alert">
												{{successMessage}}
											</div>
										</div>
									</div>
									<div class="text-center pt-15">
										<button type="button" (click)="modal.dismiss('Cross click')"
											class="btn btn-light me-3"
											data-kt-users-modal-action="cancel">Cancel</button>
										<button type="submit" class="btn btn-primary"
											data-kt-users-modal-action="submit">
											<span class="indicator-label">Submit</span>
											<span class="indicator-progress">Please wait...
												<span
													class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</form>
							</div>
						</ng-template>
					</div>
				</div>
				<div class="card-body py-4">
					<table class="table align-middle table-row-dashed fs-6 gy-5" datatable [dtOptions]="dtOptions">
						<thead>
							<tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
								<th>SR. No.</th>
								<th>subject</th>
								<th>alias</th>
								<th>Created</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody class="text-gray-600 fw-semibold" *ngIf="emailTemplateList?.length != 0">
							<tr *ngFor="let email of emailTemplateList;let i = index">
								<td>{{i+1}}</td>
								<td>{{email.subject}}</td>
								<td>{{email.alias}}</td>
								<td>{{email.created | date :'medium'}}</td>
								<td>
									<a href="javascript:;"
										(click)="openEmailTemplateView(viewEmailTemplateContent, email.id)"
										class="btn btn-sm btn-icon btn-active-color-primary">
										<i class="ki-outline ki-eye fs-2 text-primary">
											<span class="path1"></span>
											<span class="path2"></span>
										</i>
									</a>
									<a href="javascript:;"
										(click)="openEmailTemplateEdit(updateEmailTemplateContent, email.id)"
										class="btn btn-sm btn-icon btn-active-color-info">
										<i class="ki-outline ki-notepad-edit fs-2 text-info">
											<span class="path1"></span>
											<span class="path2"></span>
										</i>
									</a>
									<a href="javascript:;"
										(click)="deleteEmailConformation(deleteEmailContent,email.id)"
										class="btn btn-sm btn-icon btn-active-color-info">
										<i class="fa fa-trash">
											<span class="path1"></span>
											<span class="path2"></span>
										</i>
										</a>
								</td>
							</tr>
						</tbody>
						<tbody class="text-gray-600 fw-semibold" *ngIf="emailTemplateList?.length == 0">
							<tr>
								<td>No Record Found</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #updateEmailTemplateContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">Edit Email Template</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
			(click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
		<form [formGroup]="updateemailTemplateForm" (ngSubmit)="updateEmailTemplate($event)" class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7">
				<div class="row mb-5">
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Subject</label>
						<input type="text" class="form-control form-control-solid" formControlName="subject"
							placeholder="subject"
							[ngClass]="{'inputError': f.subject.touched && f.subject.invalid && f.subject.errors && f.subject.errors.required }" />
					</div>
					<div class="col-md-6 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Alias</label>
						<input type="text" class="form-control form-control-solid" formControlName="alias"
							placeholder="alias"
							[ngClass]="{'inputError': f.alias.touched && f.alias.invalid && f.alias.errors && f.alias.errors.required }" />
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-md-12 fv-row">
						<label class="required fs-5 fw-semibold mb-2">Message</label>
						<textarea style="height: 100px; width: 500px; border-style:solid" type="text"
							class="form-control form-control-solid" formControlName="message"
							placeholder="Email Message"
							[ngClass]="{'inputError': f.message.touched && f.message.invalid && f.message.errors && f.message.errors.required }"></textarea>
					</div>
				</div>
				<div *ngIf="errorMessage">
					<div class="alert alert-danger" role="alert">
						{{errorMessage}}
					</div>
				</div>
				<div *ngIf="successMessage">
					<div class="alert alert-success" role="alert">
						{{successMessage}}
					</div>
				</div>
			</div>
			<div class="text-center pt-15">
				<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3"
					data-kt-users-modal-action="cancel">Cancel</button>
				<button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
					<span class="indicator-label">Submit</span>
					<span class="indicator-progress">Please wait...
						<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #viewEmailTemplateContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="fw-bold">View Email Template</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
			(click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y">
		<form class="form">
			<div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true"
				data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
				data-kt-scroll-dependencies="#kt_modal_add_user_header"
				data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				<div class="table-responsive">
					<table class="table table-striped" style="line-height: 50px;;">
						<tr>
							<th class="text-right">Subject</th>
							<td>{{emailTemplateById.subject}}</td>
						</tr>
						<tr>
							<th class="text-right">Alias</th>
							<td>{{emailTemplateById.alias}}</td>
						</tr>
						<tr>
							<th class="text-right">Message</th>
							<td>{{emailTemplateById.message}}</td>
						</tr>
						<tr>
							<th class="text-right">Created</th>
							<td>{{emailTemplateById.created | date : "medium"}}</td>
						</tr>
					</table>
					<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3"
						data-kt-users-modal-action="cancel">Cancel</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #deleteEmailContent let-modal>
	<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
		(click)="modal.dismiss('Cross click')"></div>
	<h5 class="fw-bold" class="text-center">Are you sure you want to delete this Email?</h5>
	<div class="modal-body scroll-y mx-1 mx-xl-2 my-1">
		<div *ngIf="errorMessagedelete">
			<div class="alert alert-danger" role="alert">
				{{errorMessagedelete}}
			</div>
		</div>
		<div *ngIf="successMessagedelete">
			<div class="alert alert-success" role="alert">
				{{successMessagedelete}}
			</div>
		</div>
		<div class="text-center pt-2">
			<button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-light me-3"
				data-kt-users-modal-action="cancel">Cancel</button>
			<button type="submit" class="btn btn-primary" (click)="deleteEmail()" data-kt-users-modal-action="submit">
				<span class="indicator-label">Delete</span>
				<span class="indicator-progress">Please wait...
					<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
			</button>
		</div>
	</div>
</ng-template>
