<div class="d-flex flex-column flex-root">
	<div class="page d-flex flex-row flex-column-fluid">
		<div id="kt_aside" class="aside" data-kt-drawer="true" data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#kt_aside_mobile_toggle">
			<div class="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
				<div class="aside-user d-flex align-items-sm-center justify-content-center py-5">
					<div class="symbol symbol-50px">
						<img src="assets/media/avatars/blank.png" alt="" />
					</div>
					<div class="aside-user-info flex-row-fluid flex-wrap ms-5">
						<div class="d-flex">
							<div class="flex-grow-1 me-2">
								<a href="javascript:;" class="text-white text-hover-primary fs-6 fw-bold">{{name}}</a>
								<div class="d-flex align-items-center text-success fs-9">
									<span class="bullet bullet-dot bg-success me-1"></span>online
								</div>
							</div>
							<div class="me-n2">
								<a href="javascript:;" class="btn btn-icon btn-sm btn-active-color-primary mt-n2"
									data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
									data-kt-menu-overflow="true">
									<i class="ki-duotone ki-setting-2 text-muted fs-1">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</a>
								<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
									data-kt-menu="true">
									<div class="menu-item px-3">
										<div class="menu-content d-flex align-items-center px-3">
											<div class="symbol symbol-50px me-5">
												<img alt="Logo" src="assets/media/avatars/blank.png" />
											</div>
											<div class="d-flex flex-column">
												<div class="fw-bold d-flex align-items-center fs-5">{{name}}
													<span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2"
														*ngIf="(role_type == '1')">Admin</span>
													<span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2"
														*ngIf="(role_type == '3')">Blood Bank</span>
												</div>
												<a href="javascript:;"
													class="fw-semibold text-muted text-hover-primary fs-7">{{email}}</a>
											</div>
										</div>
									</div>
									<div class="separator my-2"></div>
									<div class="menu-item px-5">
										<a href="profile" *ngIf="(role_type == '1')" class="menu-link px-5">My
											Profile</a>
									</div>
									<div class="menu-item px-5">
										<a href="change_password" class="menu-link px-5">Change Password</a>
									</div>
									<div class="menu-item px-5">
										<a href="master_settings" class="menu-link px-5">Settings</a>
									</div>
									<div class="menu-item px-5">
										<a href="logout" class="menu-link px-5">Sign Out</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="aside-menu flex-column-fluid">
				<div class="hover-scroll-overlay-y px-2 my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
					data-kt-scroll-height="auto"
					data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}"
					data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="5px">
					<div class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
						id="#kt_aside_menu" data-kt-menu="true">
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'dashboard' ? 'active' : ''" href="dashboard">
								<span class="menu-icon">
									<i class="ki-duotone ki-rocket fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Dashboard</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'amenity_manage' ? 'active' : ''" href="amenity_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-logistic fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
										<span class="path4"></span>
										<span class="path5"></span>
										<span class="path6"></span>
										<span class="path7"></span>
									</i>
								</span>
								<span class="menu-title">Amenity Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'appointment_list' ? 'active' : ''" href="appointment_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-address-book fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Appointments</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'article_category' ? 'active' : ''" href="article_category">
								<span class="menu-icon">
									<i class="ki-duotone ki-status fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Article Category</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="[(currentRoute == 'article_manage' || currentRoute == 'article_add' || currentRoute == 'article_edit') ? 'active' : '']" href="article_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-book-square fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Article Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'badge_list' ? 'active' : ''" href="badge_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-medal-star fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
										<span class="path4"></span>
									</i>
								</span>
								<span class="menu-title">Badge Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'banner_list' ? 'active' : ''" href="banner_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-slider-horizontal-2 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Banner Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="[(currentRoute == 'blood_request_list' || currentRoute == 'blood_request_details') ? 'active' : '']" href="blood_request_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-syringe fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Blood Request</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'blood_type_list' ? 'active' : ''" href="blood_type_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-test-tubes fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Blood Type Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="[(currentRoute == 'business_master' || currentRoute == 'business_master_details') ? 'active' : '']" href="business_master">
								<span class="menu-icon">
									<i class="ki-duotone ki-briefcase fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Business Masters</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="[(currentRoute == 'community_list' || currentRoute == 'community_details' || currentRoute == 'community_details_post' || currentRoute == 'community_details_user' || currentRoute == 'community_details_logs') ? 'active' : '']"  href="community_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-people fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
										<span class="path4"></span>
										<span class="path5"></span>
									</i>
								</span>
								<span class="menu-title">Community Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'contact_us' ? 'active' : ''" href="contact_us">
								<span class="menu-icon">
									<i class="ki-duotone ki-delivery-24 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
										<span class="path4"></span>
										<span class="path5"></span>
										<span class="path6"></span>
										<span class="path7"></span>
										<span class="path8"></span>
									</i>
								</span>
								<span class="menu-title">Contact Us Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'department_manage' ? 'active' : ''"
								href="department_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-logistic fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
										<span class="path4"></span>
										<span class="path5"></span>
										<span class="path6"></span>
										<span class="path7"></span>
									</i>
								</span>
								<span class="menu-title">Department Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'disease_list' ? 'active' : ''" href="disease_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-virus fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
										<span class="path3"></span>
									</i>
								</span>
								<span class="menu-title">Disease Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'role_list' ? 'active' : ''" href="role_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-13 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Role Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'user_list' ? 'active' : ''" href="user_list?role_type=2">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">User Manage</span>
							</a>
						</div>
						
						
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'doctor_manage' ? 'active' : ''" href="doctor_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Doctor Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'hospital_types' ? 'active' : ''" href="hospital_types">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Hospital Types</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'version_control_list' ? 'active' : ''"
								href="version_control_list">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Version Manage</span>
							</a>
						</div>
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'email_template_manage' ? 'active' : ''"
								href="email_template_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Email Manage</span>
							</a>
						</div>
						
						<div class="menu-item">
							<a class="menu-link" *ngIf="(role_type == '1')"
								[ngClass]="currentRoute == 'newslatter_manage' ? 'active' : ''"
								href="newslatter_manage">
								<span class="menu-icon">
									<i class="ki-duotone ki-abstract-26 fs-2">
										<span class="path1"></span>
										<span class="path2"></span>
									</i>
								</span>
								<span class="menu-title">Newslatter Manage</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
