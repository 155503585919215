import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

import { ArticleService } from "../services/article.service";
import { ArticleCategoryService } from "../services/articleCategory.service";
import { UserService } from "../services/user.service";
import { Article} from "../models/article";

class ImageSnippet {
	constructor(public src: string, public file: File){}
}
@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.css']
})

export class ArticleEditComponent implements OnInit {

  	articleId: any;
	currentImage: any;
	userList: any;
  	categoryList: any;
	
	errorMessage = "";
	successMessage = "";

	selectedFile : any = ImageSnippet;
	images : string[] = [];
  
  	updateArticleForm = new FormGroup({
		title: new FormControl("", [Validators.required]),
		edit_description_content: new FormControl("", [Validators.required]),
		slug: new FormControl("", [Validators.required]),
		edit_description: new FormControl("", [Validators.required]),
		featured_image: new FormControl("", [Validators.required]),
		uploaded_by: new FormControl("", [Validators.required]),
		tags: new FormControl("", [Validators.required]),
		category_id: new FormControl("", [Validators.required]),
		status: new FormControl("", [Validators.required]),
		meta_title: new FormControl("", [Validators.required]),
		meta_keywords: new FormControl("", [Validators.required]),
		meta_description: new FormControl("", [Validators.required]),
		articleId: new FormControl("", [Validators.required]),
  	});

	constructor(
		private articleService: ArticleService,
		private articleCategoryService: ArticleCategoryService,
		private userService: UserService,
		private route: ActivatedRoute
	) { }

  	ngOnInit(): void {
		this.articleId = this.route.snapshot.paramMap.get('id');
		this.articleEdit(this.articleId);
		this.getArticleCategory();
		this.getUser();
	}

  	getArticleCategory(): void {
		this.articleCategoryService.getAllArticleCategory().subscribe(
		(data) => {
			if (data.status == 200) {
				this.categoryList = data.data;
			} else {
				this.categoryList = [];
			}
		}
		);
	}

	getUser(): void {
		this.userService.getAllAdmin().subscribe(
		(data) => {
			if (data.status == 200) {
				this.userList = data.data;
			} else {
				this.userList = [];
			}
		}
		);
	}
	
  	articleEdit(articleId: any): void {
		this.successMessage = "";
		this.errorMessage = "";
		this.articleService.getArticleById(articleId).subscribe(
		(data: any) => {
			if (data.status == 200) {
				this.updateArticleForm.controls["title"].setValue(data.data.title);
				this.updateArticleForm.controls["edit_description_content"].setValue(data.data.description);
				this.updateArticleForm.controls["featured_image"].setValue(data.data.featured_image);
				this.currentImage = data.data.featured_image;
				this.updateArticleForm.controls["slug"].setValue(data.data.slug);
				this.updateArticleForm.controls["uploaded_by"].setValue(data.data.uploaded_by);
				this.updateArticleForm.controls["tags"].patchValue(data.data.tags);
				this.updateArticleForm.controls["category_id"].setValue(data.data.category_id);
				this.updateArticleForm.controls["status"].setValue(data.data.status);
				this.updateArticleForm.controls["meta_title"].setValue(data.data.meta_title);
				this.updateArticleForm.controls["meta_keywords"].setValue(data.data.meta_keywords);
				this.updateArticleForm.controls["meta_description"].setValue(data.data.meta_description);
				this.updateArticleForm.controls["articleId"].setValue(data.data.id);
			} 
		}
		);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);
			this.articleService.uploadArticleImage(this.selectedFile.file).subscribe(
				data => {
					this.updateArticleForm.controls["featured_image"].setValue(data.data);
				},
				error => {
					this.errorMessage = error;
				}
			)
		});
		reader.readAsDataURL(file);
	}

	onTitleChange(event: any) {
		let slug = event.target.value.replace(/\s+/g, '-').toLowerCase();
		this.updateArticleForm.controls["slug"].setValue(slug);
   	};

  updateArticle($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let description = $('#edit_description_content').val();
		let title = this.updateArticleForm.value.title.trim();
		let slug = this.updateArticleForm.value.slug.trim();
		let featured_image = this.updateArticleForm.value.featured_image;
		let uploaded_by = this.updateArticleForm.value.uploaded_by;
		let tags = $('#tags-add').val();
		let category_id = this.updateArticleForm.value.category_id;
		let status = this.updateArticleForm.value.status.trim();
		let meta_title = this.updateArticleForm.value.meta_title.trim();
		let meta_keywords = this.updateArticleForm.value.meta_keywords.trim();
		let meta_description = this.updateArticleForm.value.meta_description.trim();
		let articleId = this.updateArticleForm.value.articleId;
			
		if (
			!title ||
			!description ||
			!featured_image ||
			!uploaded_by ||
			//!tags ||
			!slug ||
			!category_id ||
			!meta_title ||
			!meta_keywords ||
			!meta_description ||
			!articleId
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.articleService
		.updateArticle({
			title,
			description,
			featured_image,
      		uploaded_by,
      		tags,
      		category_id,
      		status,
      		meta_title,
			slug,
      		meta_keywords,
      		meta_description,
			articleId
		} as Article)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				setTimeout(() => {
				window.location.href = 'article_manage';
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}
