import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Amenity } from "../models/amenity";

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: "root",
})
export class RoleService {

	private getRoleUrl = environment.baseURL + "role/getRole";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(private http: HttpClient) { }

	getRole(): Observable<Amenity> {
		return this.http
			.get<Amenity>(this.getRoleUrl, this.httpOptions)
			.pipe(
				map((roleList) => {
					return roleList;
				})
			);
	}
}
