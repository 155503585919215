import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

import { CommunityService } from "../services/community.service";

@Component({
	selector: "app-community-details",
	templateUrl: "./community-details.component.html",
	styleUrls: ["./community-details.component.css"],
})
export class CommunityDetailsComponent implements OnInit {

	userId: any;
	userById: any;

	constructor(
		private communityService: CommunityService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.getCommunityById(this.userId);
	}

	getCommunityById(userId: any): void {
		this.communityService.getCommunityById(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

}
