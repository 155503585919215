<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
	<div id="kt_header" class="header align-items-stretch">
		<div class="header-brand" style="background: white;border-right: 10px solid;">
			<a href="dashboard" class="logo-title"><img src="assets/media/avatars/logo.png" alt=""
					style="width: 90%;" /> </a>
			<div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
				data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize">
				<i class="ki-duotone ki-entrance-right fs-1 me-n1 minimize-default">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
				<i class="ki-duotone ki-entrance-left fs-1 minimize-active">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
			<div class="d-flex align-items-center d-lg-none me-n2" title="Show aside menu">
				<div class="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_aside_mobile_toggle">
					<i class="ki-duotone ki-abstract-14 fs-1">
						<span class="path1"></span>
						<span class="path2"></span>
					</i>
				</div>
			</div>
		</div>
		<div class="toolbar d-flex align-items-stretch">
			<div
				class="container-xxl py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between">
				<div class="page-title d-flex justify-content-center flex-column me-5">
					<h1 class="d-flex flex-column text-dark fw-bold fs-3 mb-0">Community Details</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="post d-flex flex-column-fluid" id="kt_post">
			<div id="kt_content_container" class="container-xxl">
				<div class="card mb-5 mb-xl-10">
					<div class="card-body pt-9 pb-0">
						<div class="d-flex flex-wrap flex-sm-nowrap">
						</div>
						<ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5 active"
									href="community_details/{{userId}}">Overview</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									href="community_details_post/{{userId}}">Post</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									href="community_details_user/{{userId}}">User</a>
							</li>
							<li class="nav-item mt-2">
								<a class="nav-link text-active-primary ms-0 me-10 py-5"
									href="community_details_logs/{{userId}}">Logs</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
					<div class="card-body p-9">
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">User Name</label>
							<div class="col-lg-8">
								<span class="fw-bold fs-6 text-gray-800">{{userById.user_first_name}}
									{{userById.user_last_name}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Name</label>
							<div class="col-lg-8 fv-row">
								<span class="fw-semibold text-gray-800 fs-6">{{userById.name}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Description</label>
							<div class="col-lg-8">
								<span class="fw-semibold text-gray-800 fs-6">{{userById.description}}</span>
							</div>
						</div>
						<div class="row mb-7">
							<label class="col-lg-4 fw-semibold text-muted">Image</label>
							<div class="col-lg-8">
								<td><img src="{{userById.image}}" width="50" height="50" /></td>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Sharing URL</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.sharing_url}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Location</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.location}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Latitude</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.latitude}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Longitude</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.longitude}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Status</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.status == '1' ? 'Active' :
									'Inactive'}}</span>
							</div>
						</div>
						<div class="row mb-10">
							<label class="col-lg-4 fw-semibold text-muted">Created</label>
							<div class="col-lg-8">
								<span class="fw-semibold fs-6 text-gray-800">{{userById.date_filter}}</span>
							</div>
						</div>
						<div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<a href="community_list" class="btn btn-primary">Back</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
