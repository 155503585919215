import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BusinessMasterService } from "../services/businessMaster.service";

@Component({
	selector: 'app-business-master-details',
	templateUrl: './business-master-details.component.html',
	styleUrls: ['./business-master-details.component.css']
})

export class BusinessMasterDetailsComponent implements OnInit {

	userId: any;
	userById: any;

	constructor(
		private BusinessMasterService: BusinessMasterService,
		private route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.userId = this.route.snapshot.paramMap.get('id');
		this.BusinessDetailsById(this.userId);
	}

	overviewData = true;
	amenity = false;
	department = false;
	doctor = false;
	image = false;
	slotDetails = false;

	BusinessDetailsById(userId: any): void {
		this.BusinessMasterService.getBusinessDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = true;
					this.amenity = false;
					this.department = false;
					this.doctor = false;
					this.image = false;
					this.slotDetails = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	BusinessAmenityById(userId: any): void {
		this.BusinessMasterService.getAmenityDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.amenity = true;
					this.department = false;
					this.doctor = false;
					this.image = false;
					this.slotDetails = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	BusinessDepartmentById(userId: any): void {
		this.BusinessMasterService.getDepartmentDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.amenity = false;
					this.department = true;
					this.doctor = false;
					this.image = false;
					this.slotDetails = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	BusinessDoctorById(userId: any): void {
		this.BusinessMasterService.getDoctorDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.amenity = false;
					this.department = false;
					this.doctor = true;
					this.image = false;
					this.slotDetails = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	BusinessImageById(userId: any): void {
		this.BusinessMasterService.getImageDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.amenity = false;
					this.department = false;
					this.doctor = false;
					this.image = true;
					this.slotDetails = false;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}

	BusinessSlotDetailsById(userId: any): void {
		this.BusinessMasterService.getBusinessSlotDetails(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.userById = data.data;
					this.overviewData = false;
					this.amenity = false;
					this.department = false;
					this.doctor = false;
					this.image = false;
					this.slotDetails = true;
				} else {
					this.userById = [];
				}
			},
			(error) => {
			}
		);
	}
}
